import { Key } from "./keys";
import { Keycap } from "./keyset";
import { Layout } from "./layouts";
import { KeysetMap} from "./keyset_map";

// selectors (choose an option, first is default) 
export enum OverrideChoiceSetName {
    PHYSICAL_LAYOUT, // ansi/iso/uk iso - 60 to 100%
    MINIVAN_ARROWS,
    CANDYBAR_NUMPAD_SIDE,
};

// toggles (on/off)
export enum OverrideToggleName {
    NOVELTIES,
    GRADIENT_TOP_ROW, // 60/65%, 40%
    F13, // TKL, 100%
    SPLIT_BACKSPACE, // candybar
}

export type OverrideChoiceSet = {
    choiceName: OverrideChoiceSetName,
    // first is default option
    overrides: Array<Override>,
};

export type OverrideToggle = {
    // note: this is slightly redundant for toggles, since they have a display name in the Override
    // the toggleName is used for state, the display name is used for rendering
    // (unlike choices, where both are used for state)
    toggleName: OverrideToggleName,
    override: Override;
}

export enum OverrideDisplayName {
    // PHYSICAL_LAYOUT
    ANSI = 'ANSI',
    ISO = 'ISO',
    // Minivan arrow keys 
    LOCK_KEY = 'Lock key',
    ARROW_KEYS = 'Arrow keys',
    // Candybar numpad side
    LEFT = 'Lefty',
    RIGHT = 'Righty',
    // Toggles
    GRADIENT_NUMROW = 'Gradient numrow',
    GRADIENT_QWERTY = 'Gradient QWERTY row',
    NOVELTIES = 'Novelties',
    F13 = 'F13',
    SPLIT_BACKSPACE = 'Split backspace',
};

export type Override = {
    displayName: OverrideDisplayName,
    layoutOverride?: {
        remove: Array<Key>,
        add: Layout,
    },
    keysetOverride?: KeysetMap,
};

const PHYSICAL_LAYOUT_CHOICE_SET: OverrideChoiceSet = {
    choiceName: OverrideChoiceSetName.PHYSICAL_LAYOUT,
    overrides: [
        {displayName: OverrideDisplayName.ANSI},
        {
            displayName: OverrideDisplayName.ISO, 
            layoutOverride: {
                remove: [Key.PIPE_150, Key.ENTER_225, Key.SHIFT_225],
                add: [
                    {key: Key.ISO_ENTER, x: 13.5, y: 2.5},
                    {key: Key.ISO_PIPE, x: 12.75, y: 3.5},
                    {key: Key.SHIFT_125, x: 0, y: 4.5},
                    {key: Key.ISO_ANGLE_BRACKET, x: 1.25, y: 4.5},
                ],
            }
        },
     ]
};

const GRADIENT_NUMROW_COMMON_KEYSET_OVERRIDE: Array<[Key, Keycap]> = [
    [Key.NUMROW_ESC, Keycap.SPARKLE_DOUBLE_BLUE],
    [Key.ALPHA_1, Keycap.SPARKLE_SINGLE_BLUE],
    [Key.ALPHA_2, Keycap.SPARKLE_DOUBLE_BLUE],
    [Key.ALPHA_3, Keycap.SPARKLE_SINGLE_PURPLE],
    [Key.ALPHA_4, Keycap.SPARKLE_DOUBLE_PURPLE],
    [Key.ALPHA_5, Keycap.SPARKLE_SINGLE_PINK],
    [Key.ALPHA_6, Keycap.SPARKLE_DOUBLE_PINK],
    [Key.ALPHA_7, Keycap.SPARKLE_SINGLE_RED],
    [Key.ALPHA_8, Keycap.SPARKLE_DOUBLE_RED],
    [Key.ALPHA_9, Keycap.SPARKLE_SINGLE_ORANGE],
    [Key.ALPHA_0, Keycap.SPARKLE_DOUBLE_ORANGE],
    [Key.ALPHA_DASH, Keycap.SPARKLE_SINGLE_YELLOW],
    [Key.ALPHA_EQUALS, Keycap.SPARKLE_DOUBLE_YELLOW],
    [Key.BACKSPACE_200, Keycap.MULTISPARKLE_YELLOW_200],
];

const GRADIENT_NUMROW_OVERRIDE_TOGGLE: OverrideToggle = {
    toggleName: OverrideToggleName.GRADIENT_TOP_ROW,
    override: {
        displayName: OverrideDisplayName.GRADIENT_NUMROW,
        keysetOverride: new Map([
            ...GRADIENT_NUMROW_COMMON_KEYSET_OVERRIDE,
            [Key.MACRO_1, Keycap.SPARKLE_SINGLE_YELLOW],
            // HHKB
            [Key.TSANGAN_PIPE, Keycap.SPARKLE_SINGLE_YELLOW],
            [Key.BACKTICK, Keycap.SPARKLE_DOUBLE_YELLOW],
            // Alice
            [Key.HOME_ALICE, Keycap.SPARKLE_SINGLE_BLUE],
            // FRLTKL
            [Key.INSERT, Keycap.SPARKLE_DOUBLE_ORANGE],
            [Key.HOME, Keycap.SPARKLE_SINGLE_RED],
            [Key.PG_UP, Keycap.SPARKLE_DOUBLE_PINK],
            // FRL1800
            [Key.NUM_LOCK, Keycap.SPARKLE_DOUBLE_ORANGE],
            [Key.DIVIDE, Keycap.SPARKLE_SINGLE_RED],
            [Key.MULTIPLY, Keycap.SPARKLE_DOUBLE_PINK],
            [Key.NUMPAD_MINUS, Keycap.SPARKLE_SINGLE_PURPLE],
        ]),
    }
}

const GRADIENT_QWERTY_ROW_OVERRIDE_TOGGLE: OverrideToggle = {
    toggleName: OverrideToggleName.GRADIENT_TOP_ROW,
    override: {
        displayName: OverrideDisplayName.GRADIENT_QWERTY,
        keysetOverride: new Map([
            [Key.QWERTY_ESC, Keycap.SPARKLE_SINGLE_BLUE],
            [Key.Q, Keycap.SPARKLE_DOUBLE_BLUE],
            [Key.W, Keycap.SPARKLE_SINGLE_PURPLE],
            [Key.E, Keycap.SPARKLE_DOUBLE_PURPLE],
            [Key.R, Keycap.SPARKLE_SINGLE_PINK],
            [Key.T, Keycap.SPARKLE_DOUBLE_PINK],
            [Key.Y, Keycap.SPARKLE_SINGLE_RED],
            [Key.U, Keycap.SPARKLE_DOUBLE_RED],
            [Key.I, Keycap.SPARKLE_SINGLE_ORANGE],
            [Key.O, Keycap.SPARKLE_DOUBLE_ORANGE],
            [Key.P, Keycap.SPARKLE_SINGLE_YELLOW],
            [Key.BACK_175, Keycap.SPARKLE_DOUBLE_YELLOW_175],
            [Key.BACK_150, Keycap.SPARKLE_DOUBLE_YELLOW_150],
            // Ortho
            [Key.BACK_100, Keycap.SPARKLE_DOUBLE_YELLOW],
            // Candybar
            [Key.BACKSPACE_200, Keycap.MULTISPARKLE_YELLOW_200],
            [Key.TSANGAN_PIPE, Keycap.SPARKLE_SINGLE_YELLOW],
            [Key.NUMPAD_7, Keycap.SPARKLE_SINGLE_ORANGE],
            [Key.NUMPAD_8, Keycap.SPARKLE_DOUBLE_RED],
            [Key.NUMPAD_9, Keycap.SPARKLE_SINGLE_PINK],
            [Key.MULTIPLY, Keycap.SPARKLE_DOUBLE_PURPLE],
        ]),
    }
}

// Unlike boards below this has to be a TOGGLE since its not always on
// but QAZ also has a special mapping
const QAZ_GRADIENT_QWERTY_ROW_OVERRIDE_TOGGLE: OverrideToggle = {
    toggleName: OverrideToggleName.GRADIENT_TOP_ROW,
    override: {
        displayName: OverrideDisplayName.GRADIENT_QWERTY,
        keysetOverride: new Map([
            [Key.QWERTY_ESC, Keycap.SPARKLE_SINGLE_BLUE],
            [Key.W, Keycap.SPARKLE_DOUBLE_BLUE],
            [Key.E, Keycap.SPARKLE_SINGLE_PURPLE],
            [Key.R, Keycap.SPARKLE_DOUBLE_PURPLE],
            [Key.T, Keycap.SPARKLE_SINGLE_PINK],
            [Key.Y, Keycap.SPARKLE_DOUBLE_PINK],
            [Key.U, Keycap.SPARKLE_SINGLE_RED],
            [Key.I, Keycap.SPARKLE_DOUBLE_RED],
            [Key.O, Keycap.SPARKLE_SINGLE_ORANGE],
            [Key.BACK_125, Keycap.SPARKLE_DOUBLE_ORANGE_125],
        ]),
    }
}

// Has to be separate to above due to different mapping used on ergodox
const ERGODOX_NUMROW_OVERRIDE: Override = {
    displayName: OverrideDisplayName.GRADIENT_NUMROW,
    keysetOverride: new Map([
        [Key.ALPHA_1, Keycap.SPARKLE_SINGLE_BLUE],
        [Key.ALPHA_2, Keycap.SPARKLE_DOUBLE_BLUE],
        [Key.ALPHA_3, Keycap.SPARKLE_SINGLE_PURPLE],
        [Key.ALPHA_4, Keycap.SPARKLE_DOUBLE_PURPLE],
        [Key.ALPHA_5, Keycap.SPARKLE_SINGLE_PINK],
        [Key.ALPHA_6, Keycap.SPARKLE_DOUBLE_RED],
        [Key.ALPHA_7, Keycap.SPARKLE_SINGLE_ORANGE],
        [Key.ALPHA_8, Keycap.SPARKLE_DOUBLE_ORANGE],
        [Key.ALPHA_9, Keycap.SPARKLE_SINGLE_YELLOW],
        [Key.ALPHA_0, Keycap.SPARKLE_DOUBLE_YELLOW],
    ]),
}

const LILY58_NUMROW_OVERRIDE: Override = {
    displayName: OverrideDisplayName.GRADIENT_NUMROW,
    keysetOverride: new Map([
        [Key.ALPHA_1, Keycap.SPARKLE_SINGLE_BLUE],
        [Key.ALPHA_2, Keycap.SPARKLE_SINGLE_PURPLE],
        [Key.ALPHA_3, Keycap.SPARKLE_DOUBLE_PURPLE],
        [Key.ALPHA_4, Keycap.SPARKLE_SINGLE_PINK],
        [Key.ALPHA_5, Keycap.SPARKLE_DOUBLE_PINK],
        [Key.ALPHA_6, Keycap.SPARKLE_SINGLE_RED],
        [Key.ALPHA_7, Keycap.SPARKLE_DOUBLE_RED],
        [Key.ALPHA_8, Keycap.SPARKLE_SINGLE_ORANGE],
        [Key.ALPHA_9, Keycap.SPARKLE_DOUBLE_ORANGE],
        [Key.ALPHA_0, Keycap.SPARKLE_DOUBLE_YELLOW],
    ]),
}

// similar for preonic 
// hack: also slip in some legend reassignments compared to base planck legends
const PREONIC_NUMROW_OVERRIDE: Override = {
    displayName: OverrideDisplayName.GRADIENT_NUMROW,
    keysetOverride: new Map([
        [Key.ALPHA_1, Keycap.SPARKLE_SINGLE_BLUE],
        [Key.ALPHA_2, Keycap.SPARKLE_SINGLE_PURPLE],
        [Key.ALPHA_3, Keycap.SPARKLE_DOUBLE_PURPLE],
        [Key.ALPHA_4, Keycap.SPARKLE_SINGLE_PINK],
        [Key.ALPHA_5, Keycap.SPARKLE_DOUBLE_PINK],
        [Key.ALPHA_6, Keycap.SPARKLE_SINGLE_RED],
        [Key.ALPHA_7, Keycap.SPARKLE_DOUBLE_RED],
        [Key.ALPHA_8, Keycap.SPARKLE_SINGLE_ORANGE],
        [Key.ALPHA_9, Keycap.SPARKLE_DOUBLE_ORANGE],
        [Key.ALPHA_0, Keycap.SPARKLE_DOUBLE_YELLOW],
        [Key.QWERTY_ESC, Keycap.TEXT_TAB_100],
        [Key.TAB_100, Keycap.TEXT_ESC],
        [Key.BACK_100, Keycap.PIPE_100]
    ]),
}

const V4N_NUMROW_OVERRIDE: Override = {
    displayName: OverrideDisplayName.GRADIENT_NUMROW,
    keysetOverride: new Map([
        [Key.ALPHA_1, Keycap.SPARKLE_SINGLE_BLUE],
        [Key.ALPHA_2, Keycap.SPARKLE_DOUBLE_PURPLE],
        [Key.ALPHA_3, Keycap.SPARKLE_SINGLE_PINK],
        [Key.ALPHA_4, Keycap.SPARKLE_DOUBLE_RED],
        [Key.ALPHA_5, Keycap.SPARKLE_SINGLE_ORANGE],
        [Key.ALPHA_6, Keycap.SPARKLE_DOUBLE_YELLOW],
    ]),
};

const UT472_NOVELTIES_OVERRIDE: Override = {
    displayName: OverrideDisplayName.NOVELTIES,
    keysetOverride: new Map([
        [Key.MENU_125, Keycap.BUBBLE_125],
        [Key.CODE_125_L, Keycap.BUBBLE_125],
        [Key.CODE_125_R, Keycap.BUBBLE_125],
    ]),
}

const STANDARD_NOVELTIES_OVERRIDE_TOGGLE = {
    toggleName: OverrideToggleName.NOVELTIES,
    override: {
        displayName: OverrideDisplayName.NOVELTIES,
        keysetOverride: new Map([
            // this conflicts with GRADIENT_NUMROW 
            [Key.NUMROW_ESC, Keycap.BUTTERFLY],
            [Key.CODE_125_L, Keycap.BUTTERFLY_SIDE_LEFT_125],
            [Key.CODE_125_R, Keycap.BUTTERFLY_SIDE_RIGHT_125],
            [Key.ENTER_225, Keycap.BUBBLES_225],
            // 65/75 have 1.5u on left side only; arisu has it on both
            [Key.CONTROL_150_L, Keycap.IRIS_LEFT_150],
            [Key.CONTROL_150_R, Keycap.IRIS_RIGHT_150],
            // only used in hhkb
            [Key.ALT_100, Keycap.BUBBLE],
            // 65/75: MACRO_1 also conflicts with GRADIENT_NUMROW
            [Key.MACRO_1, Keycap.BISMUTH],
            [Key.MACRO_2, Keycap.GEM],
            [Key.MACRO_3, Keycap.FEATHER_RIGHT],
            [Key.MACRO_4, Keycap.SHELL],
            // Alice: HOME_ALICE also conflicts with GRADIENT_NUMROW
            [Key.HOME_ALICE, Keycap.BISMUTH],
            [Key.PG_UP_ALICE, Keycap.GEM],
            [Key.PG_DN_ALICE, Keycap.FEATHER_RIGHT],
            [Key.ISO_ENTER, Keycap.ISO_BUBBLES],
            [Key.NUMPAD_ENTER, Keycap.BUBBLES_200_V],
        ]),
    }
}

const FOURTIES_NOVELTIES_OVERRIDE_TOGGLE = {
    toggleName: OverrideToggleName.NOVELTIES,
    override: {
        displayName: OverrideDisplayName.NOVELTIES,
        keysetOverride: new Map([
            [Key.QWERTY_ESC, Keycap.BUTTERFLY],
            [Key.ENTER_175, Keycap.IRIS_RIGHT_175],
            [Key.ENTER_150, Keycap.FEATHER_RIGHT_150],
            [Key.ENTER_125, Keycap.GEM_125],
            [Key.CODE_125_L, Keycap.BUBBLE_125],
            [Key.CODE_125_R, Keycap.BUBBLE_125],
            [Key.CODE_150_L, Keycap.BUTTERFLY_SIDE_LEFT_150],
            [Key.CODE_150_R, Keycap.BUTTERFLY_SIDE_RIGHT_150],
            // ortho
            [Key.ENTER_100, Keycap.GEM],
            [Key.UP, Keycap.SHELL],
            [Key.DOWN, Keycap.SHELL],
            // candybar
            [Key.NUMPAD_ENTER_100, Keycap.SHELL],
        ]),
    }
}


const MINIVAN_ARROWS_CHOICE_SET: OverrideChoiceSet = {
    choiceName: OverrideChoiceSetName.MINIVAN_ARROWS,
    overrides: [
        {displayName: OverrideDisplayName.LOCK_KEY},
        {
            displayName: OverrideDisplayName.ARROW_KEYS, 
            layoutOverride: {
                remove: [Key.MENU_125, Key.CODE_150_L, Key.CODE_150_R, Key.LOCK_175, Key.SLASH],
                add: [
                    // gotta put it back lol
                    {key: Key.CODE_150_L, x: 1.75, y: 5.5},
                    {key: Key.CODE_150_R, x: 8.75, y: 5.5},
                    {key: Key.ARROW_LEFT, x: 10.25, y: 5.5},
                    {key: Key.ARROW_UP, x: 11.25, y: 4.5},
                    {key: Key.ARROW_DOWN, x: 11.25, y: 5.5},
                    {key: Key.ARROW_RIGHT, x: 12.25, y: 5.5},
                ],
            }
        },
    ]
};


const CANDYBAR_NUMPAD_CHOICE_SET: OverrideChoiceSet = {
    choiceName: OverrideChoiceSetName.CANDYBAR_NUMPAD_SIDE,
    overrides: [
        {
            displayName: OverrideDisplayName.LEFT, 
            layoutOverride: {
                remove: [],
                add: [
                    {key: Key.NUMPAD_7, x: -3.5, y: 2.5},
                    {key: Key.NUMPAD_8, x: -2.5, y: 2.5},
                    {key: Key.NUMPAD_9, x: -1.5, y: 2.5},
                    {key: Key.MULTIPLY, x: -0.5, y: 2.5},

                    {key: Key.NUMPAD_4, x: -3.5, y: 3.5},
                    {key: Key.NUMPAD_5, x: -2.5, y: 3.5},
                    {key: Key.NUMPAD_6, x: -1.5, y: 3.5},
                    {key: Key.NUMPAD_MINUS, x: -0.5, y: 3.5},

                    {key: Key.NUMPAD_1, x: -3.5, y: 4.5},
                    {key: Key.NUMPAD_2, x: -2.5, y: 4.5},
                    {key: Key.NUMPAD_3, x: -1.5, y: 4.5},
                    {key: Key.NUMPAD_PLUS_100, x: -0.5, y: 4.5},

                    {key: Key.FN, x: -3.5, y: 5.5},
                    {key: Key.NUMPAD_0_100, x: -2.5, y: 5.5},
                    {key: Key.NUMPAD_PERIOD, x: -1.5, y: 5.5},
                    {key: Key.NUMPAD_ENTER_100, x: -0.5, y: 5.5},
                ],
            }
        },       {
            // TODO(iridescent): arg when backspace is split theres two single sparkles in a row TwT
            displayName: OverrideDisplayName.RIGHT, 
            layoutOverride: {
                // move arrows over
                remove: [Key.SHIFT_125, Key.ARROW_UP, Key.ARROW_DOWN, Key.ARROW_LEFT, Key.ARROW_RIGHT],
                add: [
                    {key: Key.SHIFT_125, x: 11.25, y: 4.5},
                    {key: Key.ARROW_UP, x: 12.5, y: 4.5},

                    {key: Key.FN, x: 10.5, y: 5.5},
                    {key: Key.ARROW_LEFT, x: 11.5, y: 5.5},
                    {key: Key.ARROW_DOWN, x: 12.5, y: 5.5},
                    {key: Key.ARROW_RIGHT, x: 13.5, y: 5.5},

                    {key: Key.NUMPAD_7, x: 13.5, y: 2.5},
                    {key: Key.NUMPAD_8, x: 14.5, y: 2.5},
                    {key: Key.NUMPAD_9, x: 15.5, y: 2.5},
                    {key: Key.MULTIPLY, x: 16.5, y: 2.5},

                    {key: Key.NUMPAD_4, x: 13.5, y: 3.5},
                    {key: Key.NUMPAD_5, x: 14.5, y: 3.5},
                    {key: Key.NUMPAD_6, x: 15.5, y: 3.5},
                    {key: Key.NUMPAD_MINUS, x: 16.5, y: 3.5},

                    {key: Key.NUMPAD_1, x: 13.5, y: 4.5},
                    {key: Key.NUMPAD_2, x: 14.5, y: 4.5},
                    {key: Key.NUMPAD_3, x: 15.5, y: 4.5},
                    {key: Key.NUMPAD_PLUS_100, x: 16.5, y: 4.5},

                    {key: Key.NUMPAD_0_100, x: 14.5, y: 5.5},
                    {key: Key.NUMPAD_PERIOD, x: 15.5, y: 5.5},
                    {key: Key.NUMPAD_ENTER_100, x: 16.5, y: 5.5},
                ],
            }
        },

    ]
}

const CANDYBAR_SPLIT_BACKSPACE_OVERRIDE_TOGGLE: OverrideToggle = {
    toggleName: OverrideToggleName.SPLIT_BACKSPACE,
    override: {
        displayName: OverrideDisplayName.SPLIT_BACKSPACE, 
        layoutOverride: {
            remove: [Key.BACKSPACE_200],
            add: [
                {key: Key.BACK_100, x: 11.5, y: 2.5},
                {key: Key.TSANGAN_PIPE, x: 12.5, y: 2.5}, // laziness: means no remapping back from 2 sparkles to 1 sparkle
            ],
        }
    },
}

const F13_OVERRIDE_TOGGLE = {
    toggleName: OverrideToggleName.F13,
    override: {
        displayName: OverrideDisplayName.F13,
        layoutOverride: {
            remove: [Key.F1, Key.F2, Key.F3, Key.F4, Key.F5, Key.F6, Key.F7, Key.F8, Key.F9, Key.F10, Key.F11, Key.F12, Key.PRT_SC, Key.SCROLL, Key.PAUSE],
            add: [
                {key: Key.F1, x: 1.25, y: 0},
                {key: Key.F2, x: 2.25, y: 0},
                {key: Key.F3, x: 3.25, y: 0},
                {key: Key.F4, x: 4.25, y: 0},
                {key: Key.F5, x: 5.5, y: 0},
                {key: Key.F6, x: 6.5, y: 0},
                {key: Key.F7, x: 7.5, y: 0},
                {key: Key.F8, x: 8.5, y: 0},
                {key: Key.F9, x: 9.75, y: 0},
                {key: Key.F10, x: 10.75, y: 0},
                {key: Key.F11, x: 11.75, y: 0},
                {key: Key.F12, x: 12.75, y: 0},

                {key: Key.F13, x: 14, y: 0},
            
                {key: Key.PRT_SC, x: 15.25, y: 0},
                {key: Key.SCROLL, x: 16.25, y: 0},
                {key: Key.PAUSE, x: 17.25, y: 0},
            ],
        },
        // PRT_SC/SCROLL/PAUSE shifted over due to F13
        keysetOverride: new Map([
            [Key.PRT_SC, Keycap.SPARKLE_DOUBLE_ORANGE],
            [Key.SCROLL, Keycap.SPARKLE_SINGLE_RED],
            [Key.PAUSE, Keycap.SPARKLE_DOUBLE_PINK],
        ]),
    }
}

export const OverrideChoiceSets = {
    PHYSICAL_LAYOUT_CHOICE_SET,
    MINIVAN_ARROWS_CHOICE_SET,
    CANDYBAR_NUMPAD_CHOICE_SET,
}

export const OverrideToggles = {
    GRADIENT_NUMROW_OVERRIDE_TOGGLE,
    GRADIENT_QWERTY_ROW_OVERRIDE_TOGGLE,
    QAZ_GRADIENT_QWERTY_ROW_OVERRIDE_TOGGLE,
    STANDARD_NOVELTIES_OVERRIDE_TOGGLE,
    FOURTIES_NOVELTIES_OVERRIDE_TOGGLE,
    F13_OVERRIDE_TOGGLE,
    CANDYBAR_SPLIT_BACKSPACE_OVERRIDE_TOGGLE,
}

// These are generally turned on by default, rather than being an option (i.e. toggleName is not strictly needed)
export const KeyboardSpecificOverrides = {
    ERGODOX_NUMROW_OVERRIDE,
    LILY58_NUMROW_OVERRIDE,
    V4N_NUMROW_OVERRIDE,
    PREONIC_NUMROW_OVERRIDE,
    UT472_NOVELTIES_OVERRIDE,
}