import React from 'react';
import {Tailor} from './tailor/tailor';
import './App.css';
import './shared.css';
import { ExportKitRenderer } from './tailor/export_kit_renderer';
// import { ExportKitRenderer } from './tailor/export_kit_renderer';

function App() {
  // return (
  //   <ExportKitRenderer />
  // )
  return (
    <Tailor />
  );
}

export default App;
