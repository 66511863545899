import { Key } from "./keys";
import { Keycap } from "./keyset";

export type KeysetMap = Map<Key, Keycap>;

// would be nice to have auto matching for matched names
export const DEFAULT_KEYSET_MAP: KeysetMap = new Map([
    [Key.NUMROW_ESC, Keycap.TEXT_ESC],
    [Key.BACKTICK, Keycap.BACKTICK],
    [Key.ALPHA_1, Keycap.ALPHA_1],
    [Key.ALPHA_2, Keycap.ALPHA_2],
    [Key.ALPHA_3, Keycap.ALPHA_3],
    [Key.ALPHA_4, Keycap.ALPHA_4],
    [Key.ALPHA_5, Keycap.ALPHA_5],
    [Key.ALPHA_6, Keycap.ALPHA_6],
    [Key.ALPHA_7, Keycap.ALPHA_7],
    [Key.ALPHA_8, Keycap.ALPHA_8],
    [Key.ALPHA_9, Keycap.ALPHA_9],
    [Key.ALPHA_0, Keycap.ALPHA_0],
    [Key.ALPHA_DASH, Keycap.ALPHA_DASH],
    [Key.ALPHA_EQUALS, Keycap.ALPHA_EQUALS],

    [Key.Q, Keycap.Q],
    [Key.W, Keycap.W],
    [Key.E, Keycap.E],
    [Key.R, Keycap.R],
    [Key.T, Keycap.T],
    [Key.Y, Keycap.Y],
    [Key.U, Keycap.U],
    [Key.I, Keycap.I],
    [Key.O, Keycap.O],
    [Key.P, Keycap.P],
    [Key.LEFT_BRACKET, Keycap.LEFT_BRACKET],
    [Key.RIGHT_BRACKET, Keycap.RIGHT_BRACKET],
    [Key.PIPE_150, Keycap.PIPE_150],

    [Key.A, Keycap.A],
    [Key.S, Keycap.S],
    [Key.D, Keycap.D],
    [Key.F, Keycap.F_HOMING_BARRED],
    [Key.G, Keycap.G],
    [Key.H, Keycap.H],
    [Key.J, Keycap.J_HOMING_BARRED],
    [Key.K, Keycap.K],
    [Key.L, Keycap.L],
    [Key.SEMICOLON, Keycap.SEMICOLON],
    [Key.SINGLE_QUOTE, Keycap.SINGLE_QUOTE],

    [Key.Z, Keycap.Z],
    [Key.X, Keycap.X],
    [Key.C, Keycap.C],
    [Key.V, Keycap.V],
    [Key.B, Keycap.B],
    [Key.N, Keycap.N],
    [Key.M, Keycap.M],
    [Key.COMMA, Keycap.COMMA],
    [Key.PERIOD, Keycap.PERIOD],
    [Key.SLASH, Keycap.SLASH],

    /* Arrows */
    [Key.ARROW_UP, Keycap.ARROW_UP],
    [Key.ARROW_UP_ALT, Keycap.ARROW_UP],
    [Key.ARROW_LEFT, Keycap.ARROW_LEFT],
    [Key.ARROW_DOWN, Keycap.ARROW_DOWN],
    [Key.ARROW_RIGHT, Keycap.ARROW_RIGHT],

    /* TKL */
    [Key.ESC, Keycap.SPARKLE_DOUBLE_BLUE],
    [Key.F1, Keycap.SPARKLE_SINGLE_BLUE],
    [Key.F2, Keycap.SPARKLE_DOUBLE_BLUE],
    [Key.F3, Keycap.SPARKLE_SINGLE_PURPLE],
    [Key.F4, Keycap.SPARKLE_DOUBLE_PURPLE],
    [Key.F5, Keycap.SPARKLE_SINGLE_PINK],
    [Key.F6, Keycap.SPARKLE_DOUBLE_PINK],
    [Key.F7, Keycap.SPARKLE_SINGLE_RED],
    [Key.F8, Keycap.SPARKLE_DOUBLE_RED],
    [Key.F9, Keycap.SPARKLE_SINGLE_ORANGE],
    [Key.F10, Keycap.SPARKLE_DOUBLE_ORANGE],
    [Key.F11, Keycap.SPARKLE_SINGLE_YELLOW],
    [Key.F12, Keycap.SPARKLE_DOUBLE_YELLOW],
    [Key.PRT_SC, Keycap.SPARKLE_SINGLE_YELLOW],
    [Key.SCROLL, Keycap.SPARKLE_DOUBLE_ORANGE],
    [Key.PAUSE, Keycap.SPARKLE_SINGLE_RED],
    [Key.INSERT, Keycap.TEXT_INSERT],
    [Key.HOME, Keycap.TEXT_HOME],
    [Key.PG_UP, Keycap.TEXT_PG_UP],
    [Key.DELETE, Keycap.TEXT_DELETE],
    [Key.END, Keycap.TEXT_END],
    [Key.PG_DN, Keycap.TEXT_PG_DN],

    [Key.F13, Keycap.SPARKLE_SINGLE_YELLOW],

    [Key.BACKSPACE_200, Keycap.TEXT_BACKSPACE],
    [Key.TAB_150, Keycap.TEXT_TAB],
    [Key.CAPSLOCK_175, Keycap.TEXT_CAPS_LOCK],
    [Key.STEPPED_CAPSLOCK_175, Keycap.TEXT_CAPS_LOCK],
    [Key.ENTER_225, Keycap.TEXT_ENTER],
    [Key.SHIFT_225, Keycap.TEXT_SHIFT_225],
    [Key.SHIFT_275, Keycap.TEXT_SHIFT_275],
    [Key.CONTROL_125, Keycap.TEXT_CONTROL_125],
    [Key.CODE_125_L, Keycap.TEXT_SHINE_125],
    [Key.CODE_125_R, Keycap.TEXT_SHINE_125],
    [Key.ALT_125, Keycap.TEXT_ALT_125],
    [Key.MENU_125, Keycap.TEXT_FN_125],

    /* 65/75 */
    [Key.MACRO_1, Keycap.TEXT_HOME],
    [Key.MACRO_2, Keycap.TEXT_DELETE],
    [Key.MACRO_3, Keycap.TEXT_PG_UP],
    [Key.MACRO_4, Keycap.TEXT_PG_DN],

    /* Numpad */
    [Key.NUM_LOCK, Keycap.TEXT_NUM_LOCK],
    [Key.DIVIDE, Keycap.DIVIDE],
    [Key.MULTIPLY, Keycap.MULTIPLY],
    [Key.NUMPAD_MINUS, Keycap.NUMPAD_MINUS],
    [Key.NUMPAD_9, Keycap.NUMPAD_9], 
    [Key.NUMPAD_8, Keycap.NUMPAD_8], 
    [Key.NUMPAD_7, Keycap.NUMPAD_7], 
    [Key.NUMPAD_6, Keycap.NUMPAD_6], 
    [Key.NUMPAD_5, Keycap.NUMPAD_5], 
    [Key.NUMPAD_4, Keycap.NUMPAD_4], 
    [Key.NUMPAD_3, Keycap.NUMPAD_3], 
    [Key.NUMPAD_2, Keycap.NUMPAD_2], 
    [Key.NUMPAD_1, Keycap.NUMPAD_1], 
    [Key.NUMPAD_0_200, Keycap.NUMPAD_0_200], 
    [Key.NUMPAD_0_100, Keycap.NUMPAD_0_100], 
    [Key.NUMPAD_00, Keycap.NUMPAD_00], 
    [Key.NUMPAD_PERIOD, Keycap.NUMPAD_PERIOD], 
    [Key.NUMPAD_ENTER, Keycap.TEXT_NUMPAD_ENTER], 
    [Key.NUMPAD_PLUS_200, Keycap.NUMPAD_PLUS_200], 
    [Key.NUMPAD_PLUS_100, Keycap.NUMPAD_PLUS_100], 
    // only used in 1800 by default
    [Key.NUMPAD_FN_1, Keycap.SPARKLE_DOUBLE_ORANGE], 
    [Key.NUMPAD_FN_2, Keycap.SPARKLE_SINGLE_RED], 
    [Key.NUMPAD_FN_3, Keycap.SPARKLE_DOUBLE_PINK], 
    [Key.NUMPAD_FN_4, Keycap.SPARKLE_SINGLE_PURPLE], 

    /* ISO */
    [Key.ISO_ENTER, Keycap.TEXT_ISO_ENTER],
    [Key.ISO_PIPE, Keycap.PIPE_100], 
    [Key.ISO_ANGLE_BRACKET, Keycap.ISO_ANGLE_BRACKET],
    [Key.SHIFT_125, Keycap.TEXT_SHIFT_125],

    /* Tsangan/HHKB/WKL */
    [Key.TSANGAN_PIPE, Keycap.PIPE_100],
    [Key.BACK_150, Keycap.TEXT_BACK_150], // R2
    [Key.CONTROL_175, Keycap.TEXT_CAPS_LOCK], // R3
    [Key.SHIFT_175, Keycap.TEXT_SHIFT_175],
    [Key.FN, Keycap.TEXT_FN],
    [Key.CONTROL_150_L, Keycap.TEXT_CONTROL_150], 
    [Key.CONTROL_150_R, Keycap.TEXT_CONTROL_150], 
    [Key.CONTROL_100, Keycap.TEXT_CONTROL_100],
    [Key.CODE_100, Keycap.TEXT_SHINE_100],
    [Key.CODE_150_L, Keycap.TEXT_SHINE_150], 
    [Key.CODE_150_R, Keycap.TEXT_SHINE_150], 
    [Key.ALT_150, Keycap.TEXT_ALT_150], 
    [Key.ALT_100, Keycap.TEXT_ALT_100],

    /* Ergo/Ortho */
    [Key.R1_150_LEFT, Keycap.SPARKLE_DOUBLE_BLUE_150],
    [Key.R1_150_RIGHT, Keycap.SPARKLE_SINGLE_YELLOW_150],
    [Key.R2_150_LEFT, Keycap.TEXT_TAB],
    [Key.R2_150_RIGHT, Keycap.TEXT_BACK_150],
    [Key.R2_150_VERT, Keycap.BUBBLE_150_V], // vertical
    [Key.R3_150_LEFT, Keycap.TEXT_FN_150],
    [Key.R3_150_RIGHT, Keycap.TEXT_ENTER_150],
    [Key.R3_150_VERT, Keycap.BUBBLE_150_V], // vertical
    [Key.R4_150_LEFT, Keycap.TEXT_SHIFT_150],
    [Key.R4_150_RIGHT, Keycap.TEXT_SHIFT_150],
    [Key.R1_LEFT_INNER, Keycap.SPARKLE_DOUBLE_PINK], [Key.R1_RIGHT_INNER, Keycap.SPARKLE_SINGLE_RED], 
    [Key.R1_LEFT, Keycap.SPARKLE_DOUBLE_BLUE], [Key.R1_RIGHT, Keycap.SPARKLE_SINGLE_YELLOW], 
    [Key.ERGO_NOV_A, Keycap.GEM], [Key.ERGO_NOV_B, Keycap.BUTTERFLY], 
    // Legend also mirrored, unlike E and F
    [Key.ERGO_NOV_C_LEFT, Keycap.FEATHER_LEFT], [Key.ERGO_NOV_C_RIGHT, Keycap.FEATHER_RIGHT],
    [Key.R5_200_A, Keycap.SPACEBAR_200], 
    [Key.R5_200_B, Keycap.SPACEBAR_200], 
    [Key.R5_200_C, Keycap.SPACEBAR_200], 
    [Key.R5_200_D, Keycap.SPACEBAR_200], 
    [Key.THUMB_A, Keycap.TEXT_CONTROL_100],
    [Key.THUMB_B, Keycap.TEXT_SHINE_100],
    [Key.THUMB_C, Keycap.TEXT_HOME],
    [Key.THUMB_D, Keycap.TEXT_END],
    [Key.THUMB_E, Keycap.TEXT_PG_DN],
    [Key.THUMB_F, Keycap.TEXT_PG_UP],
    [Key.THUMB_G, Keycap.TEXT_ALT_100],
    [Key.THUMB_H, Keycap.TEXT_FN],

    /* Spacebars / Alice */
    [Key.SPACEBAR_100, Keycap.SPACEBAR_100],
    [Key.SPACEBAR_125, Keycap.SPACEBAR_125],
    [Key.SPACEBAR_150, Keycap.SPACEBAR_150],
    [Key.SPACEBAR_175, Keycap.SPACEBAR_175],
    [Key.SPACEBAR_200, Keycap.SPACEBAR_200],
    [Key.SPACEBAR_225, Keycap.SPACEBAR_225],
    [Key.SPACEBAR_275, Keycap.SPACEBAR_275],
    [Key.SPACEBAR_300, Keycap.SPACEBAR_300],
    [Key.SPACEBAR_600, Keycap.SPACEBAR_600],
    [Key.SPACEBAR_625, Keycap.SPACEBAR_625],
    [Key.SPACEBAR_700, Keycap.SPACEBAR_700],

    /* 40s */
    [Key.QWERTY_ESC, Keycap.TEXT_ESC],
    [Key.BACK_100, Keycap.TEXT_BACK_100],
    // DELETE_150,
    [Key.BACK_125, Keycap.TEXT_BACK_125],
    [Key.BACK_175, Keycap.TEXT_BACK_175],
    [Key.TAB_100, Keycap.TEXT_TAB_100],
    [Key.TAB_125, Keycap.TEXT_TAB_125],
    [Key.ENTER_100, Keycap.TEXT_ENTER_100],
    [Key.NUMPAD_ENTER_100, Keycap.TEXT_ENTER_100],
    [Key.ENTER_125, Keycap.TEXT_ENTER_125],
    [Key.ENTER_150, Keycap.TEXT_ENTER_150],
    [Key.ENTER_175, Keycap.TEXT_ENTER_175],
    [Key.SHIFT_100, Keycap.TEXT_SHIFT_100],
    // SHIFT_125,
    [Key.SHIFT_150, Keycap.TEXT_SHIFT_150],
    // SHIFT_175,
    [Key.LOCK_175, Keycap.IRIS_LEFT_175], 
    [Key.UP, Keycap.TEXT_UP],
    [Key.DOWN, Keycap.TEXT_DOWN],
    // also uses bottom row 1us, 1.25us and 1.5us
    
    /* Alice */
    [Key.HOME_ALICE, Keycap.TEXT_HOME],
    [Key.PG_UP_ALICE, Keycap.TEXT_PG_UP],
    [Key.PG_DN_ALICE, Keycap.TEXT_PG_DN],
]);
