export enum Key {
    /* Alphas */
    BACKTICK,
    ALPHA_1, ALPHA_2, ALPHA_3, ALPHA_4, ALPHA_5,
    ALPHA_6, ALPHA_7, ALPHA_8, ALPHA_9, ALPHA_0,
    ALPHA_DASH, ALPHA_EQUALS,
    Q, W, E, R, T, Y, U, I, O, P, LEFT_BRACKET, RIGHT_BRACKET, PIPE_150,
    A, S, D, F, G, H, J, K, L, SEMICOLON, SINGLE_QUOTE, 
    Z, X, C, V, B, N, M, COMMA, PERIOD, SLASH,

    /* Arrows */    
    ARROW_UP, ARROW_UP_ALT, ARROW_LEFT, ARROW_DOWN, ARROW_RIGHT,

    /* TKL */
    ESC, F1, F2, F3, F4, F5, F6, F7, F8, F9, F10, F11, F12,
    PRT_SC, SCROLL, PAUSE,
    INSERT, HOME, PG_UP,
    DELETE, END, PG_DN,
    BACKSPACE_200,
    TAB_150,
    CAPSLOCK_175, ENTER_225,
    SHIFT_225, SHIFT_275,
    CONTROL_125, CODE_125_L, CODE_125_R, ALT_125, MENU_125,
    STEPPED_CAPSLOCK_175,
    F13,

    /* 60/65 */
    NUMROW_ESC,

    /* 65/75 macro column */
    MACRO_1,
    MACRO_2,
    MACRO_3,
    MACRO_4,

    /* Numpad */
    NUM_LOCK, DIVIDE, MULTIPLY, NUMPAD_MINUS,
    NUMPAD_7, NUMPAD_8, NUMPAD_9, NUMPAD_PLUS_200,
    NUMPAD_4, NUMPAD_5, NUMPAD_6,
    NUMPAD_1, NUMPAD_2, NUMPAD_3, NUMPAD_ENTER,
    NUMPAD_0_200, NUMPAD_PERIOD,
    NUMPAD_0_100, NUMPAD_00, NUMPAD_PLUS_100, 

    NUMPAD_FN_1, NUMPAD_FN_2, NUMPAD_FN_3, NUMPAD_FN_4,

    /* ISO */
    ISO_ENTER,
    ISO_PIPE, // TODO: merge ISO, UK_ISO and TSANGAN pipe?
    ISO_ANGLE_BRACKET,
    SHIFT_125,

    /* Tsangan/HHKB/WKL */
    TSANGAN_PIPE,
    BACK_150, // R2
    CONTROL_175, // R3
    SHIFT_175,
    FN,
    CONTROL_150_L, CONTROL_150_R, CODE_100, ALT_150, ALT_100, CONTROL_100, CODE_150_L, CODE_150_R,

    /* Ergo */
    R1_150_LEFT, R1_150_RIGHT,
    R2_150_LEFT, R2_150_RIGHT, R2_150_VERT,
    R3_150_LEFT, R3_150_RIGHT, R3_150_VERT,
    R4_150_LEFT, R4_150_RIGHT,
    R1_LEFT_INNER, R1_RIGHT_INNER,
    R1_LEFT, R1_RIGHT,
    R2_A, R2_B, R2_C, R2_D, 
    R3_A, R3_B, R3_C, R3_D, 
    R4_A, R4_B, R4_C, R4_D, 
    R4_E, R4_F,
    R5_A, R5_B, R5_C, R5_D, 
    ERGO_NOV_A, ERGO_NOV_B, ERGO_NOV_C_LEFT, ERGO_NOV_C_RIGHT,
    R5_200_A, R5_200_B, R5_200_C, R5_200_D,
    THUMB_A, THUMB_B, THUMB_C, THUMB_D, THUMB_E, THUMB_F, THUMB_G, THUMB_H,
    // SPACEBAR_200,

    /* Spacebars / Alice */
    SPACEBAR_100,
    SPACEBAR_125,
    SPACEBAR_150,
    SPACEBAR_175,
    SPACEBAR_200,
    SPACEBAR_225,
    SPACEBAR_275,
    SPACEBAR_300,
    SPACEBAR_600,
    SPACEBAR_625,
    SPACEBAR_700,

    /* 40s */
    QWERTY_ESC,
    BACK_100,
    BACK_125,
    // BACK_150
    BACK_175,
    TAB_100,
    TAB_125,
    ENTER_100,
    NUMPAD_ENTER_100, // for not mixing up novelties
    ENTER_125,
    ENTER_150,
    ENTER_175,
    SHIFT_100,
    // SHIFT_125,
    SHIFT_150,
    // SHIFT_175,
    LOCK_175,
    UP,
    DOWN,
    // also uses bottom row 1us, 1.25us and 1.5us

    /* Alice - hack for home on left side of gradient & for different novelty assignments */
    HOME_ALICE,
    PG_UP_ALICE,
    PG_DN_ALICE,
};