import { KitName } from "./kits";
import { OverrideChoiceSet, OverrideToggle, OverrideChoiceSetName, OverrideToggleName, OverrideToggles, OverrideChoiceSets, OverrideDisplayName, Override, KeyboardSpecificOverrides} from "./overrides";
import { Layout, DefaultLayouts} from "./layouts";

export type CaseLayoutComponent = {
    topX: number,
    topY: number,
    width: number,
    height: number,
}

export type KeyboardLayout = {
    name: string;
    defaultLayout: Layout,
    // Always applied first, before any choice sets or toggles
    defaultKeysetOverride?: Override;
    overrideChoiceSets: Array<OverrideChoiceSet>,
    overrideToggles: Array<OverrideToggle>,
    // easier to implement as fn than as part of overrides, since it could depend on the combo of active overrides
    getKits: (choiceSetValues: Map<OverrideChoiceSetName, string>, toggleValues: Map<OverrideToggleName, boolean>) => Set<KitName>,
    // for keyboards where the default rectangle is not sufficient
    // start at 0, 0: keycaps will start at 0.5 0.5
    customCaseLayout?: Array<CaseLayoutComponent>,
}

// For boards with fn row
const FN_ROW_CHOICE_SETS: Array<OverrideChoiceSet> = [
    OverrideChoiceSets.PHYSICAL_LAYOUT_CHOICE_SET,
];
const FN_ROW_TOGGLES: Array<OverrideToggle> = [
    OverrideToggles.STANDARD_NOVELTIES_OVERRIDE_TOGGLE,
];

const NUM_ROW_CHOICE_SETS: Array<OverrideChoiceSet> = [
    OverrideChoiceSets.PHYSICAL_LAYOUT_CHOICE_SET,
];
const NUM_ROW_TOGGLES: Array<OverrideToggle> = [ 
    OverrideToggles.GRADIENT_NUMROW_OVERRIDE_TOGGLE, 
    OverrideToggles.STANDARD_NOVELTIES_OVERRIDE_TOGGLE, 
];

const FOURTIES_CHOICE_SETS: Array<OverrideChoiceSet> = [
];

const FOURTIES_TOGGLES: Array<OverrideToggle> = [
    OverrideToggles.GRADIENT_QWERTY_ROW_OVERRIDE_TOGGLE,
    OverrideToggles.FOURTIES_NOVELTIES_OVERRIDE_TOGGLE,
];

const FOURTIES_WITH_NUM_ROW_CHOICE_SETS: Array<OverrideChoiceSet> = [
];

const FOURTIES_WITH_NUM_ROW_TOGGLES: Array<OverrideToggle> = [
    OverrideToggles.FOURTIES_NOVELTIES_OVERRIDE_TOGGLE,
];

// function handleAlphaNumrow(toggleValues: Map<OverrideToggleName, boolean>, kits: Set<KitName>) {
//     // note: can't check for MOD_NUMROW here since not all standard keyboards have that tailor option atmj
//     if (toggleValues.get(OverrideToggleName.ALPHA_NUMROW)) {
//         kits.add(KitName.ALPHA_NUMROW);
//     }
// }

function getKitsFnRow(choiceSetValues: Map<OverrideChoiceSetName, string>, toggleValues: Map<OverrideToggleName, boolean>): Set<KitName> {
    const kits: Set<KitName> = new Set(); 
    kits.add(KitName.BASE);

    return kits;
}

function getKitsFnRowWithNumpad(choiceSetValues: Map<OverrideChoiceSetName, string>, toggleValues: Map<OverrideToggleName, boolean>): Set<KitName> {
    const kits: Set<KitName> = new Set(); 
    kits.add(KitName.BASE);
    kits.add(KitName.NUMPAD);

    return kits;
}

function getKitsNumrow(choiceSetValues: Map<OverrideChoiceSetName, string>, toggleValues: Map<OverrideToggleName, boolean>) {
    const kits: Set<KitName> = new Set(); 
    kits.add(KitName.BASE);

    return kits;
}

function getKitsErgo(choiceSetValues: Map<OverrideChoiceSetName, string>, toggleValues: Map<OverrideToggleName, boolean>) {
    const kits: Set<KitName> = new Set(); 
    kits.add(KitName.ERGO);

    return kits;
}

function getKits40s(choiceSetValues: Map<OverrideChoiceSetName, string>, toggleValues: Map<OverrideToggleName, boolean>) {
    const kits: Set<KitName> = new Set(); 
    kits.add(KitName.FOURTIES_ORTHO);

    return kits;
}

const FN_ROW_CATEGORY_OPTS = {
    overrideChoiceSets: FN_ROW_CHOICE_SETS,
    overrideToggles: FN_ROW_TOGGLES,
    getKits: getKitsFnRow, 
};

const NUM_ROW_CATEGORY_OPTS = {
    overrideChoiceSets: NUM_ROW_CHOICE_SETS,
    overrideToggles: NUM_ROW_TOGGLES,
    getKits: getKitsNumrow,
};

const FOURTIES_CATEGORY_OPTS = {
    overrideChoiceSets: FOURTIES_CHOICE_SETS,
    overrideToggles: FOURTIES_TOGGLES,
    getKits: getKits40s,
};

const FOURTIES_WITH_NUM_ROW_CATEGORY_OPTS = {
    overrideChoiceSets: FOURTIES_WITH_NUM_ROW_CHOICE_SETS,
    overrideToggles: FOURTIES_WITH_NUM_ROW_TOGGLES,
    getKits: getKits40s,
};

const KEYBOARD_LAYOUT_60: KeyboardLayout = {
    name: '60%',
    defaultLayout: DefaultLayouts.DEFAULT_LAYOUT_60,
    ...NUM_ROW_CATEGORY_OPTS,
};

const KEYBOARD_LAYOUT_TKL: KeyboardLayout = {
    name: 'TKL',
    defaultLayout: DefaultLayouts.DEFAULT_LAYOUT_TKL,
    ...FN_ROW_CATEGORY_OPTS,
};
// add F13
KEYBOARD_LAYOUT_TKL.overrideToggles = [OverrideToggles.F13_OVERRIDE_TOGGLE, ...KEYBOARD_LAYOUT_TKL.overrideToggles];

const KEYBOARD_LAYOUT_100: KeyboardLayout = {
    name: '100%',
    defaultLayout: DefaultLayouts.DEFAULT_LAYOUT_100,
    overrideChoiceSets: FN_ROW_CHOICE_SETS,
    overrideToggles: FN_ROW_TOGGLES,
    getKits: getKitsFnRowWithNumpad,
};
// add F13
KEYBOARD_LAYOUT_100.overrideToggles = [OverrideToggles.F13_OVERRIDE_TOGGLE, ...KEYBOARD_LAYOUT_100.overrideToggles];

const KEYBOARD_LAYOUT_1800: KeyboardLayout = {
    name: '1800',
    defaultLayout: DefaultLayouts.DEFAULT_LAYOUT_1800,
    overrideChoiceSets: FN_ROW_CHOICE_SETS,
    overrideToggles: FN_ROW_TOGGLES,
    getKits: getKitsFnRowWithNumpad,
    // so distance to bottom of arrows is less
    customCaseLayout: [
        {topX: 0, topY: 0, width: 20.25, height: 7.25},
    ],
};

const KEYBOARD_LAYOUT_FRLTKL: KeyboardLayout = {
    name: 'FRLTKL',
    defaultLayout: DefaultLayouts.DEFAULT_LAYOUT_FRLTKL,
    ...NUM_ROW_CATEGORY_OPTS,
};

const KEYBOARD_LAYOUT_FRL1800: KeyboardLayout = {
    name: 'FRL1800',
    defaultLayout: DefaultLayouts.DEFAULT_LAYOUT_FRL1800,
    ...NUM_ROW_CATEGORY_OPTS,
    // so distance to bottom of arrows is less
    customCaseLayout: [
        {topX: 0, topY: 0, width: 20.25, height: 6},
    ],
    getKits: (choiceSetValues: Map<OverrideChoiceSetName, string>, toggleValues: Map<OverrideToggleName, boolean>) => {
        const mostKits = getKitsNumrow(choiceSetValues, toggleValues);
        mostKits.add(KitName.NUMPAD);
        return mostKits;
    },
};

const KEYBOARD_LAYOUT_65: KeyboardLayout = {
    name: '65%',
    defaultLayout: DefaultLayouts.DEFAULT_LAYOUT_65,
    ...NUM_ROW_CATEGORY_OPTS,
};

const KEYBOARD_LAYOUT_75: KeyboardLayout = {
    name: '75%',
    defaultLayout: DefaultLayouts.DEFAULT_LAYOUT_75,
    ...FN_ROW_CATEGORY_OPTS,
};

const KEYBOARD_LAYOUT_ALICE: KeyboardLayout = {
    name: 'Alice',
    defaultLayout: DefaultLayouts.DEFAULT_LAYOUT_ALICE,
    overrideChoiceSets: [], // no ansi/iso choice for alice 
    overrideToggles: NUM_ROW_TOGGLES,
    getKits: getKitsNumrow,
};

const KEYBOARD_LAYOUT_HHKB: KeyboardLayout = {
    name: 'HHKB',
    defaultLayout: DefaultLayouts.DEFAULT_LAYOUT_HHKB,
    overrideChoiceSets: [], // no ansi/iso choice for alice / hhkb
    overrideToggles: NUM_ROW_TOGGLES,
    getKits: getKitsNumrow,
};

const KEYBOARD_LAYOUT_ERGODOX: KeyboardLayout = {
    name: 'Ergodox',
    defaultLayout: DefaultLayouts.DEFAULT_LAYOUT_ERGODOX,
    defaultKeysetOverride: KeyboardSpecificOverrides.ERGODOX_NUMROW_OVERRIDE,
    // ergodox has no options
    overrideChoiceSets: [],
    overrideToggles: [],
    getKits: getKitsErgo,
    customCaseLayout: [
        {topX: 0.25, topY: 0, width: 8, height: 6},
        {topX: 11.25, topY: 0, width: 8, height: 6},
        {topX: 5.75, topY: 4.75, width: 3.5, height: 3.5},
        {topX: 10.25, topY: 4.75, width: 3.5, height: 3.5},
    ],
};

const KEYBOARD_LAYOUT_LILY58: KeyboardLayout = {
    name: 'Lily58',
    defaultKeysetOverride: KeyboardSpecificOverrides.LILY58_NUMROW_OVERRIDE,
    defaultLayout: DefaultLayouts.DEFAULT_LAYOUT_LILY58,
    ...FOURTIES_WITH_NUM_ROW_CATEGORY_OPTS,
    customCaseLayout: [
        {topX: 0, topY: 0, width: 8, height: 5},
        {topX: 10.5, topY: 0, width: 8, height: 5},
        {topX: 2.5, topY: 4, width: 5.5, height: 2},
        {topX: 10.5, topY: 4, width: 5.5, height: 2},
    ],
};

const KEYBOARD_LAYOUT_PLANCK: KeyboardLayout = {
    name: 'Planck',
    defaultLayout: DefaultLayouts.DEFAULT_LAYOUT_PLANCK,
    ...FOURTIES_CATEGORY_OPTS,
};

const KEYBOARD_LAYOUT_PREONIC: KeyboardLayout = {
    name: 'Preonic',
    defaultKeysetOverride: KeyboardSpecificOverrides.PREONIC_NUMROW_OVERRIDE,
    defaultLayout: DefaultLayouts.DEFAULT_LAYOUT_PREONIC,
    ...FOURTIES_WITH_NUM_ROW_CATEGORY_OPTS,
};

const KEYBOARD_LAYOUT_MINIVAN: KeyboardLayout = {
    name: 'Minivan',
    defaultLayout: DefaultLayouts.DEFAULT_LAYOUT_MINIVAN,
    ...FOURTIES_CATEGORY_OPTS,
};
// add in minivan specific choice set
KEYBOARD_LAYOUT_MINIVAN.overrideChoiceSets = [...KEYBOARD_LAYOUT_MINIVAN.overrideChoiceSets, OverrideChoiceSets.MINIVAN_ARROWS_CHOICE_SET];

const KEYBOARD_LAYOUT_V4N: KeyboardLayout = {
    name: 'V4N',
    defaultKeysetOverride: KeyboardSpecificOverrides.V4N_NUMROW_OVERRIDE,
    defaultLayout: DefaultLayouts.DEFAULT_LAYOUT_V4N,
    ...FOURTIES_WITH_NUM_ROW_CATEGORY_OPTS,
};

const KEYBOARD_LAYOUT_UT472: KeyboardLayout = {
    name: 'UT47.2',
    defaultLayout: DefaultLayouts.DEFAULT_LAYOUT_UT472,
    // doesn't look great without novelties lmao so have this on by default
    defaultKeysetOverride: KeyboardSpecificOverrides.UT472_NOVELTIES_OVERRIDE,
    ...FOURTIES_CATEGORY_OPTS,
};

const KEYBOARD_LAYOUT_CANDYBAR: KeyboardLayout = {
    name: 'Candybar',
    defaultLayout: DefaultLayouts.DEFAULT_LAYOUT_CANDYBAR,
    overrideChoiceSets: [OverrideChoiceSets.CANDYBAR_NUMPAD_CHOICE_SET],
    overrideToggles: [...FOURTIES_TOGGLES, OverrideToggles.CANDYBAR_SPLIT_BACKSPACE_OVERRIDE_TOGGLE],
    getKits: (choiceSetValues: Map<OverrideChoiceSetName, string>, toggleValues: Map<OverrideToggleName, boolean>) => {
        const mostKits = getKits40s(choiceSetValues, toggleValues);
        mostKits.add(KitName.NUMPAD);
        return mostKits;
    },
};

const KEYBOARD_LAYOUT_QAZ: KeyboardLayout = {
    name: 'QAZ',
    defaultLayout: DefaultLayouts.DEFAULT_LAYOUT_QAZ,
    ...FOURTIES_CATEGORY_OPTS,
    // QAZ specific gradient
    overrideToggles: [OverrideToggles.QAZ_GRADIENT_QWERTY_ROW_OVERRIDE_TOGGLE, OverrideToggles.FOURTIES_NOVELTIES_OVERRIDE_TOGGLE],
};

// TODO(iridescent): 1800, FRL TKL, FRL 1800, Candybar/Milk Truck
export const KEYBOARD_LAYOUTS = [
    KEYBOARD_LAYOUT_TKL,
    KEYBOARD_LAYOUT_100,
    KEYBOARD_LAYOUT_1800,
    KEYBOARD_LAYOUT_75,
    KEYBOARD_LAYOUT_FRLTKL,
    KEYBOARD_LAYOUT_FRL1800,
    KEYBOARD_LAYOUT_65,
    KEYBOARD_LAYOUT_60,
    KEYBOARD_LAYOUT_ALICE,
    KEYBOARD_LAYOUT_HHKB,
    KEYBOARD_LAYOUT_ERGODOX,
    KEYBOARD_LAYOUT_LILY58,
    KEYBOARD_LAYOUT_PLANCK,
    KEYBOARD_LAYOUT_PREONIC,
    KEYBOARD_LAYOUT_MINIVAN,
    KEYBOARD_LAYOUT_V4N,
    KEYBOARD_LAYOUT_UT472,
    KEYBOARD_LAYOUT_CANDYBAR,
    KEYBOARD_LAYOUT_QAZ,
];