import { Key } from "./keys";

export type LayoutPosition = {
    x: number,
    y: number, 
    // Clockwise. No rotation if not specified
    rotation?: 90 | 180 | 270;
}

export type LayoutKey = LayoutPosition & {
    key: Key,
};

// TODO: rename?
export type Layout = Array<LayoutKey>;

// for 60-100%
// note: should be aligned at 0, 0 instead? maybe do that in code?
const COMMON_BASE_LAYOUT: Layout = [
    // {key: Key.BACKTICK, x: 0, y: 1.5},
    {key: Key.ALPHA_1, x: 1, y: 1.5},
    {key: Key.ALPHA_2, x: 2, y: 1.5},
    {key: Key.ALPHA_3, x: 3, y: 1.5},
    {key: Key.ALPHA_4, x: 4, y: 1.5},
    {key: Key.ALPHA_5, x: 5, y: 1.5},
    {key: Key.ALPHA_6, x: 6, y: 1.5},
    {key: Key.ALPHA_7, x: 7, y: 1.5},
    {key: Key.ALPHA_8, x: 8, y: 1.5},
    {key: Key.ALPHA_9, x: 9, y: 1.5},
    {key: Key.ALPHA_0, x: 10, y: 1.5},
    {key: Key.ALPHA_DASH, x: 11, y: 1.5},
    {key: Key.ALPHA_EQUALS, x: 12, y: 1.5},
    // {key: Key.BACKSPACE_200, x: 13, y: 1.5},

    {key: Key.TAB_150, x: 0, y: 2.5},
    {key: Key.Q, x: 1.5, y: 2.5},
    {key: Key.W, x: 2.5, y: 2.5},
    {key: Key.E, x: 3.5, y: 2.5},
    {key: Key.R, x: 4.5, y: 2.5},
    {key: Key.T, x: 5.5, y: 2.5},
    {key: Key.Y, x: 6.5, y: 2.5},
    {key: Key.U, x: 7.5, y: 2.5},
    {key: Key.I, x: 8.5, y: 2.5},
    {key: Key.O, x: 9.5, y: 2.5},
    {key: Key.P, x: 10.5, y: 2.5},
    {key: Key.LEFT_BRACKET, x: 11.5, y: 2.5},
    {key: Key.RIGHT_BRACKET, x: 12.5, y: 2.5},
    // {key: Key.PIPE_150, x: 13.5, y: 2.5},

    {key: Key.CAPSLOCK_175, x: 0, y: 3.5},
    {key: Key.A, x: 1.75, y: 3.5},
    {key: Key.S, x: 2.75, y: 3.5},
    {key: Key.D, x: 3.75, y: 3.5},
    {key: Key.F, x: 4.75, y: 3.5},
    {key: Key.G, x: 5.75, y: 3.5},
    {key: Key.H, x: 6.75, y: 3.5},
    {key: Key.J, x: 7.75, y: 3.5},
    {key: Key.K, x: 8.75, y: 3.5},
    {key: Key.L, x: 9.75, y: 3.5},
    {key: Key.SEMICOLON, x: 10.75, y: 3.5},
    {key: Key.SINGLE_QUOTE, x: 11.75, y: 3.5},
    {key: Key.ENTER_225, x: 12.75, y: 3.5},

    {key: Key.SHIFT_225, x: 0, y: 4.5},
    {key: Key.Z, x: 2.25, y: 4.5},
    {key: Key.X, x: 3.25, y: 4.5},
    {key: Key.C, x: 4.25, y: 4.5},
    {key: Key.V, x: 5.25, y: 4.5},
    {key: Key.B, x: 6.25, y: 4.5},
    {key: Key.N, x: 7.25, y: 4.5},
    {key: Key.M, x: 8.25, y: 4.5},
    {key: Key.COMMA, x: 9.25, y: 4.5},
    {key: Key.PERIOD, x: 10.25, y: 4.5},
    {key: Key.SLASH, x: 11.25, y: 4.5},
    // {key: Key.SHIFT_275, x: 12.25, y: 4.5},
];

const COMMON_BASE_LAYOUT_60: Layout = [
    ...COMMON_BASE_LAYOUT,
    // ideally these 3 would be shared with 65
    {key: Key.BACKSPACE_200, x: 13, y: 1.5},
    
    {key: Key.PIPE_150, x: 13.5, y: 2.5},

    {key: Key.SHIFT_275, x: 12.25, y: 4.5},

    {key: Key.CONTROL_125, x: 0, y: 5.5},
    {key: Key.CODE_125_L, x: 1.25, y: 5.5},
    {key: Key.ALT_125, x: 2.5, y: 5.5},
    {key: Key.SPACEBAR_625, x: 3.75, y: 5.5},
    {key: Key.ALT_125, x: 10, y: 5.5},
    {key: Key.CODE_125_R, x: 11.25, y: 5.5},
    {key: Key.MENU_125, x: 12.5, y: 5.5},
    {key: Key.CONTROL_125, x: 13.75, y: 5.5},
]

const DEFAULT_LAYOUT_60: Layout = [
    ...COMMON_BASE_LAYOUT_60,
    {key: Key.NUMROW_ESC, x: 0, y: 1.5},
];

// TKL without FN row and backtick, basically
const COMMON_BASE_LAYOUT_TKL: Layout = [
    ...COMMON_BASE_LAYOUT_60,
    {key: Key.INSERT, x: 15.25, y: 1.5},
    {key: Key.HOME, x: 16.25, y: 1.5},
    {key: Key.PG_UP, x: 17.25, y: 1.5},
    {key: Key.DELETE, x: 15.25, y: 2.5},
    {key: Key.END, x: 16.25, y: 2.5},
    {key: Key.PG_DN, x: 17.25, y: 2.5},

    {key: Key.ARROW_UP, x: 16.25, y: 4.5},
    {key: Key.ARROW_LEFT, x: 15.25, y: 5.5},
    {key: Key.ARROW_DOWN, x: 16.25, y: 5.5},
    {key: Key.ARROW_RIGHT, x: 17.25, y: 5.5},
];

const DEFAULT_LAYOUT_TKL: Layout = [
    ...COMMON_BASE_LAYOUT_TKL,
    {key: Key.BACKTICK, x: 0, y: 1.5},

    {key: Key.ESC, x: 0, y: 0},
    {key: Key.F1, x: 2, y: 0},
    {key: Key.F2, x: 3, y: 0},
    {key: Key.F3, x: 4, y: 0},
    {key: Key.F4, x: 5, y: 0},
    {key: Key.F5, x: 6.5, y: 0},
    {key: Key.F6, x: 7.5, y: 0},
    {key: Key.F7, x: 8.5, y: 0},
    {key: Key.F8, x: 9.5, y: 0},
    {key: Key.F9, x: 11, y: 0},
    {key: Key.F10, x: 12, y: 0},
    {key: Key.F11, x: 13, y: 0},
    {key: Key.F12, x: 14, y: 0},

    {key: Key.PRT_SC, x: 15.25, y: 0},
    {key: Key.SCROLL, x: 16.25, y: 0},
    {key: Key.PAUSE, x: 17.25, y: 0},
];

const DEFAULT_LAYOUT_100: Layout = [
    ...DEFAULT_LAYOUT_TKL,

    {key: Key.NUM_LOCK, x: 18.5, y: 1.5},
    {key: Key.DIVIDE, x: 19.5, y: 1.5},
    {key: Key.MULTIPLY, x: 20.5, y: 1.5},
    {key: Key.NUMPAD_MINUS, x: 21.5, y: 1.5},

    {key: Key.NUMPAD_7, x: 18.5, y: 2.5},
    {key: Key.NUMPAD_8, x: 19.5, y: 2.5},
    {key: Key.NUMPAD_9, x: 20.5, y: 2.5},
    {key: Key.NUMPAD_PLUS_200, x: 21.5, y: 2.5, rotation: 90},

    {key: Key.NUMPAD_4, x: 18.5, y: 3.5},
    {key: Key.NUMPAD_5, x: 19.5, y: 3.5},
    {key: Key.NUMPAD_6, x: 20.5, y: 3.5},

    {key: Key.NUMPAD_1, x: 18.5, y: 4.5},
    {key: Key.NUMPAD_2, x: 19.5, y: 4.5},
    {key: Key.NUMPAD_3, x: 20.5, y: 4.5},
    {key: Key.NUMPAD_ENTER, x: 21.5, y: 4.5, rotation: 90},

    {key: Key.NUMPAD_0_200, x: 18.5, y: 5.5},
    {key: Key.NUMPAD_PERIOD, x: 20.5, y: 5.5},
];

const COMMON_BASE_LAYOUT_1800: Layout = [
    ...COMMON_BASE_LAYOUT,

    {key: Key.BACKTICK, x: 0, y: 1.5},
    {key: Key.BACKSPACE_200, x: 13, y: 1.5},
    
    {key: Key.PIPE_150, x: 13.5, y: 2.5},

    {key: Key.SHIFT_175, x: 12.25, y: 4.5},

    {key: Key.CONTROL_125, x: 0, y: 5.5},
    {key: Key.CODE_125_L, x: 1.25, y: 5.5},
    {key: Key.ALT_125, x: 2.5, y: 5.5},
    {key: Key.SPACEBAR_625, x: 3.75, y: 5.5},
    {key: Key.ALT_150, x: 10, y: 5.5},
    {key: Key.CONTROL_150_R, x: 11.5, y: 5.5},

    {key: Key.ARROW_UP, x: 14.125, y: 4.75},
    {key: Key.ARROW_LEFT, x: 13.125, y: 5.75},
    {key: Key.ARROW_DOWN, x: 14.125, y: 5.75},
    {key: Key.ARROW_RIGHT, x: 15.125, y: 5.75},

    {key: Key.NUM_LOCK, x: 15.25, y: 1.5},
    {key: Key.DIVIDE, x: 16.25, y: 1.5},
    {key: Key.MULTIPLY, x: 17.25, y: 1.5},
    {key: Key.NUMPAD_MINUS, x: 18.25, y: 1.5},

    {key: Key.NUMPAD_7, x: 15.25, y: 2.5},
    {key: Key.NUMPAD_8, x: 16.25, y: 2.5},
    {key: Key.NUMPAD_9, x: 17.25, y: 2.5},
    {key: Key.NUMPAD_PLUS_200, x: 18.25, y: 2.5, rotation: 90},

    {key: Key.NUMPAD_4, x: 15.25, y: 3.5},
    {key: Key.NUMPAD_5, x: 16.25, y: 3.5},
    {key: Key.NUMPAD_6, x: 17.25, y: 3.5},

    {key: Key.NUMPAD_1, x: 15.25, y: 4.5},
    {key: Key.NUMPAD_2, x: 16.25, y: 4.5},
    {key: Key.NUMPAD_3, x: 17.25, y: 4.5},
    {key: Key.NUMPAD_ENTER, x: 18.25, y: 4.5, rotation: 90},

    {key: Key.NUMPAD_0_100, x: 16.25, y: 5.5},
    {key: Key.NUMPAD_PERIOD, x: 17.25, y: 5.5},
];

const DEFAULT_LAYOUT_1800: Layout = [
    ...COMMON_BASE_LAYOUT_1800,

    // for simplicity: F13 always (this also seems more common)
    {key: Key.ESC, x: 0, y: 0.25},
    {key: Key.F1, x: 1.25, y: 0.25},
    {key: Key.F2, x: 2.25, y: 0.25},
    {key: Key.F3, x: 3.25, y: 0.25},
    {key: Key.F4, x: 4.25, y: 0.25},
    {key: Key.F5, x: 5.5, y: 0.25},
    {key: Key.F6, x: 6.5, y: 0.25},
    {key: Key.F7, x: 7.5, y: 0.25},
    {key: Key.F8, x: 8.5, y: 0.25},
    {key: Key.F9, x: 9.75, y: 0.25},
    {key: Key.F10, x: 10.75, y: 0.25},
    {key: Key.F11, x: 11.75, y: 0.25},
    {key: Key.F12, x: 12.75, y: 0.25},
    {key: Key.F13, x: 14, y: 0.25},
            
    {key: Key.NUMPAD_FN_1, x: 15.25, y: 0.25},
    {key: Key.NUMPAD_FN_2, x: 16.25, y: 0.25},
    {key: Key.NUMPAD_FN_3, x: 17.25, y: 0.25},
    {key: Key.NUMPAD_FN_4, x: 18.25, y: 0.25},
];

const DEFAULT_LAYOUT_FRLTKL: Layout = [
    ...COMMON_BASE_LAYOUT_TKL,

    {key: Key.NUMROW_ESC, x: 0, y: 1.5},
];

const DEFAULT_LAYOUT_FRL1800: Layout = [
    ...COMMON_BASE_LAYOUT_1800,

    {key: Key.NUMROW_ESC, x: 0, y: 1.5},
];


const COMMON_BASE_LAYOUT_65: Layout = [
    ...COMMON_BASE_LAYOUT,
    {key: Key.BACKSPACE_200, x: 13, y: 1.5},

    {key: Key.PIPE_150, x: 13.5, y: 2.5},

    {key: Key.SHIFT_175, x: 12.25, y: 4.5},

    {key: Key.ARROW_UP, x: 14, y: 4.5},
    {key: Key.ARROW_LEFT, x: 13, y: 5.5},
    {key: Key.ARROW_DOWN, x: 14, y: 5.5},
    {key: Key.ARROW_RIGHT, x: 15, y: 5.5},

    {key: Key.MACRO_1, x: 15, y: 1.5},
    {key: Key.MACRO_2, x: 15, y: 2.5},
    {key: Key.MACRO_3, x: 15, y: 3.5},
    {key: Key.MACRO_4, x: 15, y: 4.5},

    {key: Key.CONTROL_125, x: 0, y: 5.5},
    {key: Key.CODE_125_L, x: 1.25, y: 5.5},
    {key: Key.ALT_125, x: 2.5, y: 5.5},
    {key: Key.SPACEBAR_625, x: 3.75, y: 5.5},
    {key: Key.ALT_150, x: 10, y: 5.5},
    {key: Key.CONTROL_150_R, x: 11.5, y: 5.5},
]

const DEFAULT_LAYOUT_65: Layout = [
    ...COMMON_BASE_LAYOUT_65,
    {key: Key.NUMROW_ESC, x: 0, y: 1.5},
];

const DEFAULT_LAYOUT_75: Layout = [
    ...COMMON_BASE_LAYOUT_65,
    {key: Key.BACKTICK, x: 0, y: 1.5},

    {key: Key.ESC, x: 0, y: 0.5},
    {key: Key.F1, x: 1, y: 0.5},
    {key: Key.F2, x: 2, y: 0.5},
    {key: Key.F3, x: 3, y: 0.5},
    {key: Key.F4, x: 4, y: 0.5},
    {key: Key.F5, x: 5, y: 0.5},
    {key: Key.F6, x: 6, y: 0.5},
    {key: Key.F7, x: 7, y: 0.5},
    {key: Key.F8, x: 8, y: 0.5},
    {key: Key.F9, x: 9, y: 0.5},
    {key: Key.F10, x: 10, y: 0.5},
    {key: Key.F11, x: 11, y: 0.5},
    {key: Key.F12, x: 12, y: 0.5},

    {key: Key.PRT_SC, x: 13, y: 0.5},
    {key: Key.SCROLL, x: 14, y: 0.5},
    {key: Key.PAUSE, x: 15, y: 0.5},
];

const DEFAULT_LAYOUT_HHKB: Layout = [
    ...COMMON_BASE_LAYOUT,
    {key: Key.NUMROW_ESC, x: 0, y: 1.5},
    {key: Key.TSANGAN_PIPE, x: 13, y: 1.5},
    {key: Key.BACKTICK, x: 14, y: 1.5},

    {key: Key.BACK_150, x: 13.5, y: 2.5},
    
    {key: Key.SHIFT_175, x: 12.25, y: 4.5},
    {key: Key.FN, x: 14, y: 4.5},

    // TODO: if large circles are removed from novelty base kit, this needs to be fixed to be accurate
    {key: Key.ALT_100, x: 1.5, y: 5.5},
    {key: Key.CODE_150_L, x: 2.5, y: 5.5},
    {key: Key.SPACEBAR_700, x: 4, y: 5.5},
    {key: Key.CODE_150_R, x: 11, y: 5.5},
    {key: Key.ALT_100, x: 12.5, y: 5.5},
];

// unclear if 60% is standard / needs HHKB top row
// const DEFAULT_LAYOUT_WKL: Layout = [
//     ...COMMON_BASE_LAYOUT,
//     {key: Key.NUMROW_ESC, x: 0, y: 1.5},

//     {key: Key.BACKSPACE_200, x: 13, y: 1.5},
//     {key: Key.PIPE_150, x: 13.5, y: 2.5},

//     {key: Key.SHIFT_175, x: 12.25, y: 4.5},
//     {key: Key.FN, x: 14, y: 4.5},

//     {key: Key.CONTROL_150, x: 0, y: 5.5},
//     {key: Key.ALT_150, x: 2.5, y: 5.5},
//     {key: Key.SPACEBAR_700, x: 4, y: 5.5},
//     {key: Key.ALT_150, x: 11, y: 5.5},
//     {key: Key.CONTROL_150, x: 13.5, y: 5.5},
// ]

const SPLIT_ORTHO_ALPHAS: Layout = [
    {key: Key.ALPHA_1, x: 1.5, y: 0},
    {key: Key.ALPHA_2, x: 2.5, y: 0},
    {key: Key.ALPHA_3, x: 3.5, y: 0},
    {key: Key.ALPHA_4, x: 4.5, y: 0},
    {key: Key.ALPHA_5, x: 5.5, y: 0},
    {key: Key.ALPHA_6, x: 12, y: 0},
    {key: Key.ALPHA_7, x: 13, y: 0},
    {key: Key.ALPHA_8, x: 14, y: 0},
    {key: Key.ALPHA_9, x: 15, y: 0},
    {key: Key.ALPHA_0, x: 16, y: 0},

    {key: Key.Q, x: 1.5, y: 1},
    {key: Key.W, x: 2.5, y: 1},
    {key: Key.E, x: 3.5, y: 1},
    {key: Key.R, x: 4.5, y: 1},
    {key: Key.T, x: 5.5, y: 1},
    {key: Key.Y, x: 12, y: 1},
    {key: Key.U, x: 13, y: 1},
    {key: Key.I, x: 14, y: 1},
    {key: Key.O, x: 15, y: 1},
    {key: Key.P, x: 16, y: 1},

    {key: Key.A, x: 1.5, y: 2},
    {key: Key.S, x: 2.5, y: 2},
    {key: Key.D, x: 3.5, y: 2},
    {key: Key.F, x: 4.5, y: 2},
    {key: Key.G, x: 5.5, y: 2},
    {key: Key.H, x: 12, y: 2},
    {key: Key.J, x: 13, y: 2},
    {key: Key.K, x: 14, y: 2},
    {key: Key.L, x: 15, y: 2},
    {key: Key.SEMICOLON, x: 16, y: 2},

    {key: Key.Z, x: 1.5, y: 3},
    {key: Key.X, x: 2.5, y: 3},
    {key: Key.C, x: 3.5, y: 3},
    {key: Key.V, x: 4.5, y: 3},
    {key: Key.B, x: 5.5, y: 3},
    {key: Key.N, x: 12, y: 3},
    {key: Key.M, x: 13, y: 3},
    {key: Key.COMMA, x: 14, y: 3},
    {key: Key.PERIOD, x: 15, y: 3},
    {key: Key.SLASH, x: 16, y: 3},
];

const DEFAULT_LAYOUT_ERGODOX: Layout = [
    ...SPLIT_ORTHO_ALPHAS,
    {key: Key.R1_150_LEFT, x: 0, y: 0},
    {key: Key.R1_LEFT_INNER, x: 6.5, y: 0},
    {key: Key.R1_RIGHT_INNER, x: 11, y: 0},
    {key: Key.R1_150_RIGHT, x: 17, y: 0},

    {key: Key.R2_150_LEFT, x: 0, y: 1},
    {key: Key.R2_150_VERT, x: 6.5, y: 1, rotation: 270},
    {key: Key.R2_150_VERT, x: 11, y: 1, rotation: 270},
    {key: Key.R2_150_RIGHT, x: 17, y: 1},

    {key: Key.R3_150_LEFT, x: 0, y: 2},
    {key: Key.R3_150_RIGHT, x: 17, y: 2},

    {key: Key.R3_150_VERT, x: 6.5, y: 2.5, rotation: 90},
    {key: Key.R3_150_VERT, x: 11, y: 2.5, rotation: 90},

    {key: Key.R4_150_LEFT, x: 0, y: 3},
    {key: Key.R4_150_RIGHT, x: 17, y: 3},

    {key: Key.ERGO_NOV_A, x: 0.5, y: 4},
    {key: Key.ERGO_NOV_B, x: 1.5, y: 4},
    {key: Key.ERGO_NOV_C_LEFT, x: 2.5, y: 4},
    {key: Key.ARROW_LEFT, x: 3.5, y: 4},
    {key: Key.ARROW_RIGHT, x: 4.5, y: 4},

    {key: Key.ARROW_UP_ALT, x: 13, y: 4},
    {key: Key.ARROW_DOWN, x: 14, y: 4},
    {key: Key.ERGO_NOV_C_RIGHT, x: 15, y: 4},
    {key: Key.ERGO_NOV_B, x: 16, y: 4},
    {key: Key.ERGO_NOV_A, x: 17, y: 4},

    {key: Key.R5_200_A, x: 5.5, y: 5.5, rotation: 90},
    {key: Key.R5_200_B, x: 6.5, y: 5.5, rotation: 90},
    {key: Key.THUMB_A, x: 6.5, y: 4.5},
    {key: Key.THUMB_B, x: 7.5, y: 4.5},
    {key: Key.THUMB_C, x: 7.5, y: 5.5},
    {key: Key.THUMB_D, x: 7.5, y: 6.5},

    {key: Key.THUMB_E, x: 10, y: 6.5},
    {key: Key.THUMB_F, x: 10, y: 5.5},
    {key: Key.THUMB_G, x: 10, y: 4.5},
    {key: Key.THUMB_H, x: 11, y: 4.5},
    {key: Key.R5_200_C, x: 11, y: 5.5, rotation: 90},
    {key: Key.R5_200_D, x: 12, y: 5.5, rotation: 90},
];

const DEFAULT_LAYOUT_LILY58: Layout = [
    ...SPLIT_ORTHO_ALPHAS,
    {key: Key.R1_LEFT, x: 0.5, y: 0},
    {key: Key.R1_RIGHT, x: 17, y: 0},
 
    {key: Key.TAB_100, x: 0.5, y: 1},
    {key: Key.ISO_PIPE, x: 17, y: 1},
 
    {key: Key.NUMROW_ESC, x: 0.5, y: 2},
    {key: Key.ENTER_100, x: 17, y: 2},
 
    {key: Key.SHIFT_100, x: 0.5, y: 3},
    {key: Key.SHIFT_100, x: 17, y: 3},
 
    // ugh, this is usually {[}] but don't have those keys andd ont want to add them. use spacebars as blanks for now
    {key: Key.SPACEBAR_100, x: 6.5, y: 2.5},
    {key: Key.SPACEBAR_100, x: 11, y: 2.5},
 
    {key: Key.SPACEBAR_150, x: 6.5, y: 3.75, rotation: 270},
    {key: Key.SPACEBAR_150, x: 11, y: 3.75, rotation: 270},
 
    {key: Key.CONTROL_100, x: 3, y: 4},
    {key: Key.CODE_100, x: 4, y: 4},
    {key: Key.UP, x: 5, y: 4},
 
    {key: Key.DOWN, x: 12.5, y: 4},
    {key: Key.ALT_100, x: 13.5, y: 4},
    {key: Key.FN, x: 14.5, y: 4},
];

const DEFAULT_LAYOUT_PLANCK: Layout = [
    {key: Key.Q, x: 1, y: 1},
    {key: Key.W, x: 2, y: 1},
    {key: Key.E, x: 3, y: 1},
    {key: Key.R, x: 4, y: 1},
    {key: Key.T, x: 5, y: 1},
    {key: Key.Y, x: 6, y: 1},
    {key: Key.U, x: 7, y: 1},
    {key: Key.I, x: 8, y: 1},
    {key: Key.O, x: 9, y: 1},
    {key: Key.P, x: 10, y: 1},

    {key: Key.A, x: 1, y: 2},
    {key: Key.S, x: 2, y: 2},
    {key: Key.D, x: 3, y: 2},
    {key: Key.F, x: 4, y: 2},
    {key: Key.G, x: 5, y: 2},
    {key: Key.H, x: 6, y: 2},
    {key: Key.J, x: 7, y: 2},
    {key: Key.K, x: 8, y: 2},
    {key: Key.L, x: 9, y: 2},
    {key: Key.SEMICOLON, x: 10, y: 2},

    {key: Key.Z, x: 1, y: 3},
    {key: Key.X, x: 2, y: 3},
    {key: Key.C, x: 3, y: 3},
    {key: Key.V, x: 4, y: 3},
    {key: Key.B, x: 5, y: 3},
    {key: Key.N, x: 6, y: 3},
    {key: Key.M, x: 7, y: 3},
    {key: Key.COMMA, x: 8, y: 3},
    {key: Key.PERIOD, x: 9, y: 3},
    {key: Key.SLASH, x: 10, y: 3},

    {key: Key.QWERTY_ESC, x: 0, y: 1},
    {key: Key.BACK_100, x: 11, y: 1},

    {key: Key.TAB_100, x: 0, y: 2},
    {key: Key.ENTER_100, x: 11, y: 2},

    {key: Key.SHIFT_100, x: 0, y: 3},
    {key: Key.SHIFT_100, x: 11, y: 3},

    {key: Key.CONTROL_100, x: 0, y: 4},
    {key: Key.ALT_100, x: 1, y: 4},
    {key: Key.CODE_100, x: 2, y: 4},
    {key: Key.FN, x: 3, y: 4},
    {key: Key.UP, x: 4, y: 4},
    {key: Key.SPACEBAR_200, x: 5, y: 4},
    {key: Key.DOWN, x: 7, y: 4},
    {key: Key.ARROW_LEFT, x: 8, y: 4},
    {key: Key.ARROW_DOWN, x: 9, y: 4},
    {key: Key.ARROW_UP_ALT, x: 10, y: 4},
    {key: Key.ARROW_RIGHT, x: 11, y: 4},

];

const DEFAULT_LAYOUT_PREONIC: Layout = [
    ...DEFAULT_LAYOUT_PLANCK,
    {key: Key.R1_LEFT, x: 0, y: 0},
    {key: Key.R1_RIGHT, x: 11, y: 0},
    {key: Key.ALPHA_1, x: 1, y: 0},
    {key: Key.ALPHA_2, x: 2, y: 0},
    {key: Key.ALPHA_3, x: 3, y: 0},
    {key: Key.ALPHA_4, x: 4, y: 0},
    {key: Key.ALPHA_5, x: 5, y: 0},
    {key: Key.ALPHA_6, x: 6, y: 0},
    {key: Key.ALPHA_7, x: 7, y: 0},
    {key: Key.ALPHA_8, x: 8, y: 0},
    {key: Key.ALPHA_9, x: 9, y: 0},
    {key: Key.ALPHA_0, x: 10, y: 0},
];

const VAN_ALPHAS: Layout = [
    {key: Key.Q, x: 1.5, y: 2.5},
    {key: Key.W, x: 2.5, y: 2.5},
    {key: Key.E, x: 3.5, y: 2.5},
    {key: Key.R, x: 4.5, y: 2.5},
    {key: Key.T, x: 5.5, y: 2.5},
    {key: Key.Y, x: 6.5, y: 2.5},
    {key: Key.U, x: 7.5, y: 2.5},
    {key: Key.I, x: 8.5, y: 2.5},
    {key: Key.O, x: 9.5, y: 2.5},
    {key: Key.P, x: 10.5, y: 2.5},

    {key: Key.A, x: 1.75, y: 3.5},
    {key: Key.S, x: 2.75, y: 3.5},
    {key: Key.D, x: 3.75, y: 3.5},
    {key: Key.F, x: 4.75, y: 3.5},
    {key: Key.G, x: 5.75, y: 3.5},
    {key: Key.H, x: 6.75, y: 3.5},
    {key: Key.J, x: 7.75, y: 3.5},
    {key: Key.K, x: 8.75, y: 3.5},
    {key: Key.L, x: 9.75, y: 3.5},
    {key: Key.SEMICOLON, x: 10.75, y: 3.5},
    {key: Key.Z, x: 2.25, y: 4.5},
    {key: Key.X, x: 3.25, y: 4.5},
    {key: Key.C, x: 4.25, y: 4.5},
    {key: Key.V, x: 5.25, y: 4.5},
    {key: Key.B, x: 6.25, y: 4.5},
    {key: Key.N, x: 7.25, y: 4.5},
    {key: Key.M, x: 8.25, y: 4.5},
    {key: Key.COMMA, x: 9.25, y: 4.5},
    {key: Key.PERIOD, x: 10.25, y: 4.5},
];

const VAN_SHARED: Layout = [
    ...VAN_ALPHAS,

    {key: Key.QWERTY_ESC, x: 0.5, y: 2.5},
    {key: Key.BACK_175, x: 11.5, y: 2.5},

    {key: Key.TAB_125, x: 0.5, y: 3.5},
    {key: Key.ENTER_150, x: 11.75, y: 3.5},

    {key: Key.SHIFT_175, x: 0.5, y: 4.5},

    {key: Key.SHIFT_100, x: 12.25, y: 4.5},
];

const DEFAULT_LAYOUT_MINIVAN: Layout = [
    ...VAN_SHARED,

    {key: Key.SLASH, x: 11.25, y: 4.5},

    {key: Key.CONTROL_125, x: 0.5, y: 5.5},
    {key: Key.CODE_150_L, x: 1.75, y: 5.5},
    {key: Key.ALT_125, x: 3.25, y: 5.5},
    {key: Key.SPACEBAR_225, x: 4.5, y: 5.5},
    {key: Key.SPACEBAR_200, x: 6.75, y: 5.5},
    {key: Key.MENU_125, x: 8.75, y: 5.5},
    {key: Key.CODE_150_R, x: 10, y: 5.5},
    {key: Key.LOCK_175, x: 11.5, y: 5.5},
];

const DEFAULT_LAYOUT_V4N: Layout = [
    ...VAN_SHARED,

    {key: Key.ALPHA_1, x: 1, y: 1.5},
    {key: Key.ALPHA_2, x: 2, y: 1.5},
    {key: Key.ALPHA_3, x: 3, y: 1.5},
    {key: Key.ALPHA_4, x: 4, y: 1.5},
    {key: Key.ALPHA_5, x: 5, y: 1.5},
    {key: Key.ALPHA_6, x: 6, y: 1.5},

    {key: Key.ARROW_UP, x: 11.25, y: 4.5},

    {key: Key.CONTROL_125, x: 0.5, y: 5.5},
    {key: Key.FN, x: 1.75, y: 5.5},
    {key: Key.CODE_125_L, x: 2.75, y: 5.5}, // somewhat contrived to be lazy with novelties
    {key: Key.SPACEBAR_625, x: 4, y: 5.5},
    {key: Key.ARROW_LEFT, x: 10.25, y: 5.5},
    {key: Key.ARROW_DOWN, x: 11.25, y: 5.5},
    {key: Key.ARROW_RIGHT, x: 12.25, y: 5.5},
];

const DEFAULT_LAYOUT_UT472: Layout = [
    {key: Key.QWERTY_ESC, x: 0.5, y: 2.5},
    {key: Key.Q, x: 1.5, y: 2.5},
    {key: Key.W, x: 2.5, y: 2.5},
    {key: Key.E, x: 3.5, y: 2.5},
    {key: Key.R, x: 4.5, y: 2.5},
    {key: Key.T, x: 5.5, y: 2.5},
    {key: Key.Y, x: 6.5, y: 2.5},
    {key: Key.U, x: 7.5, y: 2.5},
    {key: Key.I, x: 8.5, y: 2.5},
    {key: Key.O, x: 9.5, y: 2.5},
    {key: Key.P, x: 10.5, y: 2.5},
    {key: Key.BACK_150, x: 11.5, y: 2.5},

    {key: Key.TAB_125, x: 0.5, y: 3.5},
    {key: Key.A, x: 1.75, y: 3.5},
    {key: Key.S, x: 2.75, y: 3.5},
    {key: Key.D, x: 3.75, y: 3.5},
    {key: Key.F, x: 4.75, y: 3.5},
    {key: Key.G, x: 5.75, y: 3.5},
    {key: Key.H, x: 6.75, y: 3.5},
    {key: Key.J, x: 7.75, y: 3.5},
    {key: Key.K, x: 8.75, y: 3.5},
    {key: Key.L, x: 9.75, y: 3.5},
    {key: Key.SEMICOLON, x: 10.75, y: 3.5},
    {key: Key.ENTER_125, x: 11.75, y: 3.5},

    {key: Key.SHIFT_150, x: 0.5, y: 4.5},
    {key: Key.Z, x: 2, y: 4.5},
    {key: Key.X, x: 3, y: 4.5},
    {key: Key.C, x: 4, y: 4.5},
    {key: Key.V, x: 5, y: 4.5},
    {key: Key.B, x: 6, y: 4.5},
    {key: Key.N, x: 7, y: 4.5},
    {key: Key.M, x: 8, y: 4.5},
    {key: Key.COMMA, x: 9, y: 4.5},
    {key: Key.PERIOD, x: 10, y: 4.5},
    {key: Key.SLASH, x: 11, y: 4.5},
    {key: Key.SHIFT_100, x: 12, y: 4.5},

    {key: Key.CONTROL_100, x: 0.5, y: 5.5},
    {key: Key.ALT_100, x: 1.5, y: 5.5},
    {key: Key.CODE_100, x: 2.5, y: 5.5},
    {key: Key.FN, x: 3.5, y: 5.5},
    {key: Key.MENU_125, x: 4.5, y: 5.5},
    {key: Key.SPACEBAR_200, x: 5.75, y: 5.5},
    {key: Key.CODE_125_R, x: 7.75, y: 5.5},
    {key: Key.ARROW_LEFT, x: 9, y: 5.5},
    {key: Key.ARROW_DOWN, x: 10, y: 5.5},
    {key: Key.ARROW_UP_ALT, x: 11, y: 5.5},
    {key: Key.ARROW_RIGHT, x: 12, y: 5.5},
];

const DEFAULT_LAYOUT_CANDYBAR: Layout = [
    ...VAN_ALPHAS,

    {key: Key.QWERTY_ESC, x: 0.5, y: 2.5},
    {key: Key.BACKSPACE_200, x: 11.5, y: 2.5},

    {key: Key.TAB_125, x: 0.5, y: 3.5},
    {key: Key.ENTER_175, x: 11.75, y: 3.5},

    {key: Key.SHIFT_175, x: 0.5, y: 4.5},

    {key: Key.SHIFT_125, x: 12.25, y: 4.5},

    {key: Key.ARROW_UP, x: 11.25, y: 4.5},

    {key: Key.CONTROL_125, x: 0.5, y: 5.5},
    {key: Key.CODE_125_L, x: 1.75, y: 5.5},
    {key: Key.ALT_125, x: 3, y: 5.5}, 
    {key: Key.SPACEBAR_275, x: 4.25, y: 5.5},
    {key: Key.SPACEBAR_225, x: 7, y: 5.5},
    {key: Key.CODE_125_R, x: 9.25, y: 5.5},
    {key: Key.ARROW_LEFT, x: 10.5, y: 5.5},
    {key: Key.ARROW_DOWN, x: 11.5, y: 5.5},
    {key: Key.ARROW_RIGHT, x: 12.5, y: 5.5},

    // Numpads added in options
];

const DEFAULT_LAYOUT_QAZ: Layout = [
    {key: Key.QWERTY_ESC, x: 1.5, y: 2.5},
    {key: Key.W, x: 2.5, y: 2.5},
    {key: Key.E, x: 3.5, y: 2.5},
    {key: Key.R, x: 4.5, y: 2.5},
    {key: Key.T, x: 5.5, y: 2.5},
    {key: Key.Y, x: 6.5, y: 2.5},
    {key: Key.U, x: 7.5, y: 2.5},
    {key: Key.I, x: 8.5, y: 2.5},
    {key: Key.O, x: 9.5, y: 2.5},
    {key: Key.BACK_125, x: 10.5, y: 2.5},

    {key: Key.TAB_125, x: 1.5, y: 3.5},
    {key: Key.S, x: 2.75, y: 3.5},
    {key: Key.D, x: 3.75, y: 3.5},
    {key: Key.F, x: 4.75, y: 3.5},
    {key: Key.G, x: 5.75, y: 3.5},
    {key: Key.H, x: 6.75, y: 3.5},
    {key: Key.J, x: 7.75, y: 3.5},
    {key: Key.K, x: 8.75, y: 3.5},
    {key: Key.L, x: 9.75, y: 3.5},
    {key: Key.ENTER_100, x: 10.75, y: 3.5},

    {key: Key.SHIFT_175, x: 1.5, y: 4.5},
    {key: Key.X, x: 3.25, y: 4.5},
    {key: Key.C, x: 4.25, y: 4.5},
    {key: Key.V, x: 5.25, y: 4.5},
    {key: Key.B, x: 6.25, y: 4.5},
    {key: Key.N, x: 7.25, y: 4.5},
    {key: Key.M, x: 8.25, y: 4.5},
    {key: Key.COMMA, x: 9.25, y: 4.5},
    {key: Key.SHIFT_150, x: 10.25, y: 4.5},

    {key: Key.CONTROL_100, x: 1.5, y: 5.5},
    {key: Key.CODE_100, x: 2.5, y: 5.5},
    {key: Key.UP, x: 3.5, y: 5.5}, // somewhat contrived for novelties
    {key: Key.SPACEBAR_225, x: 4.5, y: 5.5},
    {key: Key.SPACEBAR_200, x: 6.75, y: 5.5},
    {key: Key.DOWN, x: 8.75, y: 5.5},
    {key: Key.ALT_100, x: 9.75, y: 5.5},
    {key: Key.FN, x: 10.75, y: 5.5},
];


const DEFAULT_LAYOUT_ALICE: Layout = [
    {key: Key.HOME_ALICE, x: -0.25, y: 0},
    {key: Key.PG_UP_ALICE, x: -0.375, y: 1},
    {key: Key.PG_DN_ALICE, x: -0.5, y: 2},

    {key: Key.NUMROW_ESC, x: 1, y:0},
    {key: Key.TAB_150, x: 0.825, y: 1},
    {key: Key.CAPSLOCK_175, x: 0.75, y: 2}, // update overrides if you move this
    {key: Key.SHIFT_225, x: 0.625, y: 3},

    {key: Key.CONTROL_150_L, x: 0.625, y: 4},
    {key: Key.ALT_150, x: 4, y: 4},
    {key: Key.SPACEBAR_200, x: 5.5, y: 4},
    {key: Key.CODE_125_L, x: 7.5, y: 4},

    {key: Key.SPACEBAR_275, x: 9.25, y: 4},
    {key: Key.ALT_150, x: 12, y: 4},
    {key: Key.CONTROL_150_R, x: 16.75, y: 4},

    {key: Key.ALPHA_1, x: 2, y: 0},
    {key: Key.ALPHA_2, x: 3, y: 0},
    {key: Key.ALPHA_3, x: 4, y: 0},
    {key: Key.ALPHA_4, x: 5, y: 0},
    {key: Key.ALPHA_5, x: 6, y: 0},
    {key: Key.ALPHA_6, x: 7, y: 0},
    {key: Key.ALPHA_7, x: 10, y: 0},
    {key: Key.ALPHA_8, x: 11, y: 0},
    {key: Key.ALPHA_9, x: 12, y: 0},
    {key: Key.ALPHA_0, x: 13, y: 0},
    {key: Key.ALPHA_DASH, x: 14, y: 0},
    {key: Key.ALPHA_EQUALS, x: 15, y: 0},
    {key: Key.BACKSPACE_200, x: 16, y: 0},

    {key: Key.Q, x: 2.375, y: 1},
    {key: Key.W, x: 3.5, y: 1},
    {key: Key.E, x: 4.5, y: 1},
    {key: Key.R, x: 5.5, y: 1},
    {key: Key.T, x: 6.5, y: 1},
    {key: Key.Y, x: 9.5, y: 1},
    {key: Key.U, x: 10.5, y: 1},
    {key: Key.I, x: 11.5, y: 1},
    {key: Key.O, x: 12.5, y: 1},
    {key: Key.P, x: 13.625, y: 1},
    {key: Key.LEFT_BRACKET, x: 14.625, y: 1},
    {key: Key.RIGHT_BRACKET, x: 15.625, y: 1},
    {key: Key.PIPE_150, x: 16.625, y: 1},

    {key: Key.A, x: 2.625, y: 2},
    {key: Key.S, x: 3.75, y: 2},
    {key: Key.D, x: 4.75, y: 2},
    {key: Key.F, x: 5.75, y: 2},
    {key: Key.G, x: 6.75, y: 2},
    {key: Key.H, x: 9.75, y: 2},
    {key: Key.J, x: 10.75, y: 2},
    {key: Key.K, x: 11.75, y: 2},
    {key: Key.L, x: 12.75, y: 2},
    {key: Key.SEMICOLON, x: 13.875, y: 2},
    {key: Key.SINGLE_QUOTE, x: 15, y: 2},
    {key: Key.ENTER_225, x: 16, y: 2},

    {key: Key.Z, x: 3.125, y: 3},
    {key: Key.X, x: 4.25, y: 3},
    {key: Key.C, x: 5.25, y: 3},
    {key: Key.V, x: 6.25, y: 3},
    {key: Key.B, x: 7.25, y: 3},
    {key: Key.B, x: 9.25, y: 3},
    {key: Key.N, x: 10.25, y: 3},
    {key: Key.M, x: 11.25, y: 3},
    {key: Key.COMMA, x: 12.25, y: 3},
    {key: Key.PERIOD, x: 13.5, y: 3},
    {key: Key.SLASH, x: 14.625, y: 3},
    {key: Key.SHIFT_175, x: 15.625, y: 3},
    {key: Key.FN, x: 17.375, y: 3}, 
];

export const DefaultLayouts = {
    DEFAULT_LAYOUT_60,
    DEFAULT_LAYOUT_TKL,
    DEFAULT_LAYOUT_100,
    DEFAULT_LAYOUT_1800,
    DEFAULT_LAYOUT_FRLTKL,
    DEFAULT_LAYOUT_FRL1800,
    DEFAULT_LAYOUT_65,
    DEFAULT_LAYOUT_75,
    DEFAULT_LAYOUT_HHKB,
    DEFAULT_LAYOUT_ALICE,
    DEFAULT_LAYOUT_ERGODOX,
    DEFAULT_LAYOUT_LILY58,
    DEFAULT_LAYOUT_PLANCK,
    DEFAULT_LAYOUT_PREONIC,
    DEFAULT_LAYOUT_MINIVAN,
    DEFAULT_LAYOUT_V4N,
    DEFAULT_LAYOUT_UT472,
    DEFAULT_LAYOUT_CANDYBAR,
    DEFAULT_LAYOUT_QAZ,
};
