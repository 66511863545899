import { Keycap } from './keyset';
import { KeycapWithPosition } from './types';

export enum KitName {
    BASE = 'Base',
    FOURTIES_ORTHO = '40s/Ortho',
    ERGO = 'Ergo',
    NUMPAD = 'Numpad',
}

export type KitDefinition = {
    name: KitName,
    description?: string;
    keycapWithPositions: Array<KeycapWithPosition>;
};

// TODO: helper to count kit size :^)

const alphas: Array<KeycapWithPosition> = [
    {keycap: Keycap.BACKTICK, x: 0, y: 1.5},
    {keycap: Keycap.ALPHA_1, x: 1, y: 1.5},
    {keycap: Keycap.ALPHA_2, x: 2, y: 1.5},
    {keycap: Keycap.ALPHA_3, x: 3, y: 1.5},
    {keycap: Keycap.ALPHA_4, x: 4, y: 1.5},
    {keycap: Keycap.ALPHA_5, x: 5, y: 1.5},
    {keycap: Keycap.ALPHA_6, x: 6, y: 1.5},
    {keycap: Keycap.ALPHA_7, x: 7, y: 1.5},
    {keycap: Keycap.ALPHA_8, x: 8, y: 1.5},
    {keycap: Keycap.ALPHA_9, x: 9, y: 1.5},
    {keycap: Keycap.ALPHA_0, x: 10, y: 1.5},
    {keycap: Keycap.ALPHA_DASH, x: 11, y: 1.5},
    {keycap: Keycap.ALPHA_EQUALS, x: 12, y: 1.5},
    // {keycap: Keycap.BACKSPACE_200, x: 13, y: 1.5},

    // {keycap: Keycap.TAB_150, x: 0, y: 2.5},
    {keycap: Keycap.Q, x: 1.5, y: 2.5},
    {keycap: Keycap.W, x: 2.5, y: 2.5},
    {keycap: Keycap.E, x: 3.5, y: 2.5},
    {keycap: Keycap.R, x: 4.5, y: 2.5},
    {keycap: Keycap.T, x: 5.5, y: 2.5},
    {keycap: Keycap.Y, x: 6.5, y: 2.5},
    {keycap: Keycap.U, x: 7.5, y: 2.5},
    {keycap: Keycap.I, x: 8.5, y: 2.5},
    {keycap: Keycap.O, x: 9.5, y: 2.5},
    {keycap: Keycap.P, x: 10.5, y: 2.5},
    {keycap: Keycap.LEFT_BRACKET, x: 11.5, y: 2.5},
    {keycap: Keycap.RIGHT_BRACKET, x: 12.5, y: 2.5},
    // {keycap: Keycap.PIPE_150, x: 13.5, y: 2.5},

    // {keycap: Keycap.CAPSLOCK_175, x: 0, y: 3.5},
    {keycap: Keycap.A, x: 1.75, y: 3.5},
    {keycap: Keycap.S, x: 2.75, y: 3.5},
    {keycap: Keycap.D, x: 3.75, y: 3.5},
    {keycap: Keycap.F_HOMING_BARRED, x: 4.75, y: 3.5},
    {keycap: Keycap.G, x: 5.75, y: 3.5},
    {keycap: Keycap.H, x: 6.75, y: 3.5},
    {keycap: Keycap.J_HOMING_BARRED, x: 7.75, y: 3.5},
    {keycap: Keycap.K, x: 8.75, y: 3.5},
    {keycap: Keycap.L, x: 9.75, y: 3.5},
    {keycap: Keycap.SEMICOLON, x: 10.75, y: 3.5},
    {keycap: Keycap.SINGLE_QUOTE, x: 11.75, y: 3.5},
    // {keycap: Keycap.ENTER_225, x: 12.75, y: 3.5},

    // {keycap: Keycap.SHIFT_225, x: 0, y: 4.5},
    {keycap: Keycap.Z, x: 2.25, y: 4.5},
    {keycap: Keycap.X, x: 3.25, y: 4.5},
    {keycap: Keycap.C, x: 4.25, y: 4.5},
    {keycap: Keycap.V, x: 5.25, y: 4.5},
    {keycap: Keycap.B, x: 6.25, y: 4.5},
    {keycap: Keycap.N, x: 7.25, y: 4.5},
    {keycap: Keycap.M, x: 8.25, y: 4.5},
    {keycap: Keycap.COMMA, x: 9.25, y: 4.5},
    {keycap: Keycap.PERIOD, x: 10.25, y: 4.5},
    {keycap: Keycap.SLASH, x: 11.25, y: 4.5},
    // {keycap: Keycap.SHIFT_275, x: 12.25, y: 4.5},
];

const commonArrows: Array<KeycapWithPosition> = [
    {keycap: Keycap.ARROW_UP, x: 16.25, y: 4.5},
    {keycap: Keycap.ARROW_LEFT, x: 15.25, y: 5.5},
    {keycap: Keycap.ARROW_DOWN, x: 16.25, y: 5.5},
    {keycap: Keycap.ARROW_RIGHT, x: 17.25, y: 5.5},
];

// DONE
const textMod60: Array<KeycapWithPosition> = [
    {keycap: Keycap.TEXT_BACKSPACE, x: 13, y: 1.5},

    {keycap: Keycap.TEXT_TAB, x: 0, y: 2.5},

    {keycap: Keycap.TEXT_SHIFT_225, x: 0, y: 4.5}, 
    {keycap: Keycap.TEXT_SHIFT_275, x: 12.25, y: 4.5},

    {keycap: Keycap.TEXT_CAPS_LOCK, x: 0, y: 3.5},
    {keycap: Keycap.TEXT_ENTER, x: 12.75, y: 3.5},

    {keycap: Keycap.TEXT_CONTROL_125, x: 0, y: 5.5},
    {keycap: Keycap.TEXT_SHINE_125, x: 1.25, y: 5.5},
    {keycap: Keycap.TEXT_ALT_125, x: 2.5, y: 5.5},
    // {keycap: Keycap.SPACEBAR_625, x: 3.75, y: 5.5},
    {keycap: Keycap.TEXT_ALT_125, x: 10, y: 5.5},
    {keycap: Keycap.TEXT_SHINE_125, x: 11.25, y: 5.5},
    {keycap: Keycap.TEXT_FN_125, x: 12.5, y: 5.5},
    {keycap: Keycap.TEXT_CONTROL_125, x: 13.75, y: 5.5},

    ...commonArrows,
];

// DONE
const textModTKL: Array<KeycapWithPosition> = [
    {keycap: Keycap.SPARKLE_DOUBLE_BLUE, x: 0, y: 0},
    {keycap: Keycap.SPARKLE_SINGLE_BLUE, x: 2, y: 0},
    {keycap: Keycap.SPARKLE_DOUBLE_BLUE, x: 3, y: 0},
    {keycap: Keycap.SPARKLE_SINGLE_PURPLE, x: 4, y: 0},
    {keycap: Keycap.SPARKLE_DOUBLE_PURPLE, x: 5, y: 0},
    {keycap: Keycap.SPARKLE_SINGLE_PINK, x: 6.5, y: 0},
    {keycap: Keycap.SPARKLE_DOUBLE_PINK, x: 7.5, y: 0},
    {keycap: Keycap.SPARKLE_SINGLE_RED, x: 8.5, y: 0},
    {keycap: Keycap.SPARKLE_DOUBLE_RED, x: 9.5, y: 0},
    {keycap: Keycap.SPARKLE_SINGLE_ORANGE, x: 11, y: 0},
    {keycap: Keycap.SPARKLE_DOUBLE_ORANGE, x: 12, y: 0},
    {keycap: Keycap.SPARKLE_SINGLE_YELLOW, x: 13, y: 0},
    {keycap: Keycap.SPARKLE_DOUBLE_YELLOW, x: 14, y: 0},

    {keycap: Keycap.SPARKLE_SINGLE_YELLOW, x: 15.25, y: 0},
    {keycap: Keycap.SPARKLE_DOUBLE_ORANGE, x: 16.25, y: 0},
    {keycap: Keycap.SPARKLE_SINGLE_RED, x: 17.25, y: 0},

    {keycap: Keycap.SPARKLE_DOUBLE_PINK, x: 18.5, y: 0},

    {keycap: Keycap.TEXT_INSERT, x: 15.25, y: 1.5},
    {keycap: Keycap.TEXT_HOME, x: 16.25, y: 1.5},
    {keycap: Keycap.TEXT_PG_UP, x: 17.25, y: 1.5},
    {keycap: Keycap.TEXT_DELETE, x: 15.25, y: 2.5},
    {keycap: Keycap.TEXT_END, x: 16.25, y: 2.5},
    {keycap: Keycap.TEXT_PG_DN, x: 17.25, y: 2.5},

    ...commonArrows,
];

const BASE_KIT: KitDefinition = {
    name: KitName.BASE,
    keycapWithPositions: [
        ...alphas,
        ...textMod60,
        ...textModTKL,

        {keycap: Keycap.PIPE_150, x: 13.5, y: 2.5},

        {keycap: Keycap.SPACEBAR_625, x: 3.75, y: 5.5},

        {keycap: Keycap.SPARKLE_SINGLE_BLUE, x: 0, y: 7.5},
        {keycap: Keycap.TEXT_ESC, x: 1, y: 7.5},

        {keycap: Keycap.PIPE_100, x: 10, y: 7.5},
        {keycap: Keycap.TEXT_DELETE, x: 11, y: 7.5},
        {keycap: Keycap.SPARKLE_DOUBLE_YELLOW, x: 12, y: 7.5},
        {keycap: Keycap.MULTISPARKLE_YELLOW_200, x: 13, y: 7.5},

        {keycap: Keycap.TEXT_BACK_150, x: 12, y: 8.5},
        {keycap: Keycap.TEXT_ISO_ENTER, x: 13.5, y: 8.5},

        {keycap: Keycap.TEXT_CAPS_STEPPED, x: 0, y: 9.5}, 
        {keycap: Keycap.TEXT_CONTROL_175, x: 1.75, y: 9.5},  
        {keycap: Keycap.F_HOMING_SCOOPED, x: 4.75, y: 9.5}, 
        {keycap: Keycap.J_HOMING_SCOOPED, x: 7.75, y: 9.5}, 
        {keycap: Keycap.PIPE_100, x: 12.75, y: 9.5}, 

        {keycap: Keycap.TEXT_SHIFT_200, x: 0, y: 10.5},
        {keycap: Keycap.TEXT_SHIFT_125, x: 2, y: 10.5},
        {keycap: Keycap.ISO_ANGLE_BRACKET, x: 3.25, y: 10.5},
        {keycap: Keycap.B, x: 6.25, y: 10.5}, 
        {keycap: Keycap.TEXT_SHIFT_225, x: 10, y: 10.5},
        {keycap: Keycap.TEXT_SHIFT_175, x: 12.25, y: 10.5},
        {keycap: Keycap.TEXT_FN, x: 14, y: 10.5},

        {keycap: Keycap.TEXT_CONTROL_150, x: 0, y: 11.5},
        {keycap: Keycap.TEXT_SHINE_100, x: 1.5, y: 11.5},
        {keycap: Keycap.TEXT_ALT_150, x: 2.5, y: 11.5},
        {keycap: Keycap.SPACEBAR_700, x: 4, y: 11.5},
        {keycap: Keycap.TEXT_ALT_150, x: 11, y: 11.5},
        {keycap: Keycap.TEXT_SHINE_100, x: 12.5, y: 11.5},
        {keycap: Keycap.TEXT_CONTROL_150, x: 13.5, y: 11.5},

        {keycap: Keycap.TEXT_CONTROL_100, x: 0.5, y: 12.5},
        {keycap: Keycap.TEXT_ALT_100, x: 1.5, y: 12.5},
        {keycap: Keycap.TEXT_SHINE_150, x: 2.5, y: 12.5},
        {keycap: Keycap.SPACEBAR_200, x: 4, y: 12.5},
        {keycap: Keycap.SPACEBAR_225, x: 6, y: 12.5},
        {keycap: Keycap.SPACEBAR_275, x: 8.25, y: 12.5},
        {keycap: Keycap.TEXT_SHINE_150, x: 11, y: 12.5},
        {keycap: Keycap.TEXT_ALT_100, x: 12.5, y: 12.5},
        {keycap: Keycap.TEXT_CONTROL_100, x: 13.5, y: 12.5},

        {keycap: Keycap.TEXT_END, x: 15.25, y: 7.5},
        {keycap: Keycap.TEXT_DOWN, x: 16.25, y: 7.5},
        {keycap: Keycap.TEXT_UP, x: 15.25, y: 8.5},
        {keycap: Keycap.TEXT_DOWN, x: 15.25, y: 9.5},
        {keycap: Keycap.TEXT_UP, x: 16.25, y: 9.5},
        {keycap: Keycap.TEXT_END, x: 15.25, y: 10.5},
        {keycap: Keycap.TEXT_DOWN, x: 16.25, y: 10.5},

        {keycap: Keycap.BUTTERFLY, x: 18.5, y: 7.5},
        {keycap: Keycap.BISMUTH, x: 19.5, y: 7.5},
        {keycap: Keycap.GEM, x: 18.5, y: 8.5},
        {keycap: Keycap.ISO_BUBBLES, x: 21.5, y: 8.5}, 
        {keycap: Keycap.FEATHER_RIGHT, x: 18.5, y: 9.5},
        {keycap: Keycap.BUBBLES_225, x: 19.5, y: 9.5},
        {keycap: Keycap.SHELL, x: 18.5, y: 10.5},
        {keycap: Keycap.BUBBLE, x: 18.5, y: 11.5},
        {keycap: Keycap.BUBBLE, x: 19.5, y: 11.5},
        {keycap: Keycap.BUTTERFLY_SIDE_LEFT_125, x: 20.5, y: 11.5},
        {keycap: Keycap.BUTTERFLY_SIDE_RIGHT_125, x: 21.75, y: 11.5},
        {keycap: Keycap.IRIS_LEFT_150, x: 18.5, y: 12.5},
        {keycap: Keycap.IRIS_RIGHT_150, x: 20, y: 12.5},
    ],
};

const NUMPAD_KIT: KitDefinition = {
    name: KitName.NUMPAD,
    keycapWithPositions: [
        {keycap: Keycap.SPARKLE_DOUBLE_PINK, x: 18.5, y: 0},
        {keycap: Keycap.SPARKLE_SINGLE_PURPLE, x: 19.5, y: 0},
        {keycap: Keycap.SPARKLE_DOUBLE_BLUE, x: 20.5, y: 0},
        {keycap: Keycap.SPARKLE_SINGLE_BLUE, x: 21.5, y: 0},

        {keycap: Keycap.TEXT_NUM_LOCK, x: 18.5, y: 1.5},
        {keycap: Keycap.DIVIDE, x: 19.5, y: 1.5},
        {keycap: Keycap.MULTIPLY, x: 20.5, y: 1.5},
        {keycap: Keycap.NUMPAD_MINUS, x: 21.5, y: 1.5},

        {keycap: Keycap.NUMPAD_7, x: 18.5, y: 2.5},
        {keycap: Keycap.NUMPAD_8, x: 19.5, y: 2.5},
        {keycap: Keycap.NUMPAD_9, x: 20.5, y: 2.5},
        {keycap: Keycap.NUMPAD_PLUS_200, x: 21.5, y: 2.5, rotation: 90},

        {keycap: Keycap.NUMPAD_4, x: 18.5, y: 3.5},
        {keycap: Keycap.NUMPAD_5_HOMING_BARRED, x: 19.5, y: 3.5},
        {keycap: Keycap.NUMPAD_6, x: 20.5, y: 3.5},

        {keycap: Keycap.NUMPAD_1, x: 18.5, y: 4.5},
        {keycap: Keycap.NUMPAD_2, x: 19.5, y: 4.5},
        {keycap: Keycap.NUMPAD_3, x: 20.5, y: 4.5},
        {keycap: Keycap.TEXT_NUMPAD_ENTER, x: 21.5, y: 4.5, rotation: 90},

        {keycap: Keycap.NUMPAD_0_200, x: 18.5, y: 5.5},
        {keycap: Keycap.NUMPAD_PERIOD, x: 20.5, y: 5.5},
        {keycap: Keycap.NUMPAD_0_100, x: 18.5, y: 6.5},
        {keycap: Keycap.NUMPAD_00, x: 19.5, y: 6.5},

        {keycap: Keycap.NUMPAD_EQUALS, x: 23, y: 1.5},
        {keycap: Keycap.NUMPAD_MINUS, x: 23, y: 2.5},
        {keycap: Keycap.NUMPAD_PLUS_100, x: 23, y: 3.5},
        {keycap: Keycap.BUBBLES_200_V, x: 23, y: 4.5, rotation: 90}, 
        {keycap: Keycap.NUMPAD_5_HOMING_SCOOPED, x: 24, y: 3.5}, 

        {keycap: Keycap.SPARKLE_SINGLE_ORANGE, x: 26, y: 2.5},
        {keycap: Keycap.SPARKLE_DOUBLE_RED, x: 27, y: 2.5},
        {keycap: Keycap.SPARKLE_SINGLE_PINK, x: 28, y: 2.5},
        {keycap: Keycap.SPARKLE_DOUBLE_PURPLE, x: 29, y: 2.5},
        {keycap: Keycap.MULTIPLY, x: 30.5, y: 2.5},
        {keycap: Keycap.NUMPAD_MINUS, x: 30.5, y: 3.5},
        {keycap: Keycap.NUMPAD_PLUS_100, x: 30.5, y: 4.5},
    ],
};

const ERGO_KIT: KitDefinition = {
    name: KitName.ERGO,
    keycapWithPositions: [    
        {keycap: Keycap.SPARKLE_DOUBLE_BLUE_150, x: 0, y: 0},
        {keycap: Keycap.SPARKLE_DOUBLE_PINK, x: 6.5, y: 0},
        {keycap: Keycap.SPARKLE_SINGLE_RED, x: 11, y: 0},
        {keycap: Keycap.SPARKLE_SINGLE_YELLOW_150, x: 17, y: 0},

        {keycap: Keycap.SPARKLE_SINGLE_BLUE, x: 1.5, y: 0},
        {keycap: Keycap.SPARKLE_DOUBLE_BLUE, x: 2.5, y: 0},
        {keycap: Keycap.SPARKLE_SINGLE_PURPLE, x: 3.5, y: 0},
        {keycap: Keycap.SPARKLE_DOUBLE_PURPLE, x: 4.5, y: 0},
        {keycap: Keycap.SPARKLE_SINGLE_PINK, x: 5.5, y: 0},
        {keycap: Keycap.SPARKLE_DOUBLE_RED, x: 12, y: 0},
        {keycap: Keycap.SPARKLE_SINGLE_ORANGE, x: 13, y: 0},
        {keycap: Keycap.SPARKLE_DOUBLE_ORANGE, x: 14, y: 0},
        {keycap: Keycap.SPARKLE_SINGLE_YELLOW, x: 15, y: 0},
        {keycap: Keycap.SPARKLE_DOUBLE_YELLOW, x: 16, y: 0},

        {keycap: Keycap.TEXT_TAB, x: 0, y: 1},
        {keycap: Keycap.BUBBLE_150_V, x: 6.5, y: 1, rotation: 270},
        {keycap: Keycap.BUBBLE_150_V, x: 11, y: 1, rotation: 270},
        {keycap: Keycap.PIPE_150, x: 17, y: 1},
        {keycap: Keycap.TEXT_BACK_150, x: 19, y: 1},

        {keycap: Keycap.Q, x: 1.5, y: 1},
        {keycap: Keycap.W, x: 2.5, y: 1},
        {keycap: Keycap.E, x: 3.5, y: 1},
        {keycap: Keycap.R, x: 4.5, y: 1},
        {keycap: Keycap.T, x: 5.5, y: 1},
        {keycap: Keycap.Y, x: 12, y: 1},
        {keycap: Keycap.U, x: 13, y: 1},
        {keycap: Keycap.I, x: 14, y: 1},
        {keycap: Keycap.O, x: 15, y: 1},
        {keycap: Keycap.P, x: 16, y: 1},

        {keycap: Keycap.BUBBLE, x: 8, y: 1},
        {keycap: Keycap.SHELL, x: 8, y: 2},
        {keycap: Keycap.BISMUTH, x: 8, y: 3},
        {keycap: Keycap.BUBBLE, x: 9.5, y: 1},
        {keycap: Keycap.SHELL, x: 9.5, y: 2},
        {keycap: Keycap.BISMUTH, x: 9.5, y: 3},

        {keycap: Keycap.TEXT_CONTROL_150, x: -2, y: 2},
        {keycap: Keycap.TEXT_FN_150, x: 0, y: 2},
        {keycap: Keycap.TEXT_SINGLE_QUOTE_150, x: 17, y: 2},
        {keycap: Keycap.TEXT_ENTER_150, x: 19, y: 2},

        {keycap: Keycap.BUBBLE_150_V, x: 6.5, y: 2.5, rotation: 90},
        {keycap: Keycap.BUBBLE_150_V, x: 11, y: 2.5, rotation: 90},

        {keycap: Keycap.A, x: 1.5, y: 2},
        {keycap: Keycap.S, x: 2.5, y: 2},
        {keycap: Keycap.D, x: 3.5, y: 2},
        {keycap: Keycap.F_HOMING_BARRED, x: 4.5, y: 2},
        {keycap: Keycap.G, x: 5.5, y: 2},
        {keycap: Keycap.H, x: 12, y: 2},
        {keycap: Keycap.J_HOMING_BARRED, x: 13, y: 2},
        {keycap: Keycap.K, x: 14, y: 2},
        {keycap: Keycap.L, x: 15, y: 2},
        {keycap: Keycap.SEMICOLON, x: 16, y: 2},

        {keycap: Keycap.TEXT_SHIFT_150, x: 0, y: 3},
        {keycap: Keycap.TEXT_SHIFT_150, x: 17, y: 3},
        {keycap: Keycap.BUTTERFLY_SIDE_LEFT_150, x: -2, y: 3},
        {keycap: Keycap.BUTTERFLY_SIDE_RIGHT_150, x: 19, y: 3},

        {keycap: Keycap.Z, x: 1.5, y: 3},
        {keycap: Keycap.X, x: 2.5, y: 3},
        {keycap: Keycap.C, x: 3.5, y: 3},
        {keycap: Keycap.V, x: 4.5, y: 3},
        {keycap: Keycap.B, x: 5.5, y: 3},
        {keycap: Keycap.N, x: 12, y: 3},
        {keycap: Keycap.M, x: 13, y: 3},
        {keycap: Keycap.COMMA, x: 14, y: 3},
        {keycap: Keycap.PERIOD, x: 15, y: 3},
        {keycap: Keycap.SLASH, x: 16, y: 3},

        {keycap: Keycap.BACKTICK, x: -2, y: 4},
        {keycap: Keycap.PIPE_100, x: -1, y: 4},

        {keycap: Keycap.GEM, x: 0.5, y: 4},
        {keycap: Keycap.BUTTERFLY, x: 1.5, y: 4},
        {keycap: Keycap.FEATHER_LEFT, x: 2.5, y: 4},
        {keycap: Keycap.ARROW_LEFT, x: 3.5, y: 4},
        {keycap: Keycap.ARROW_RIGHT, x: 4.5, y: 4},

        {keycap: Keycap.ARROW_UP, x: 13, y: 4},
        {keycap: Keycap.ARROW_DOWN, x: 14, y: 4},
        {keycap: Keycap.FEATHER_RIGHT, x: 15, y: 4},
        {keycap: Keycap.BUTTERFLY, x: 16, y: 4},
        {keycap: Keycap.GEM, x: 17, y: 4},

        {keycap: Keycap.LEFT_BRACKET, x: 18.5, y: 4},
        {keycap: Keycap.RIGHT_BRACKET, x: 19.5, y: 4},

        {keycap: Keycap.SPACEBAR_200, x: 3.5, y: 6.5, rotation: 90},
        {keycap: Keycap.SPACEBAR_200, x: 4.5, y: 6.5, rotation: 90},
        {keycap: Keycap.BLANK_200, x: 5.5, y: 6.5, rotation: 90},
        {keycap: Keycap.BLANK_200, x: 6.5, y: 6.5, rotation: 90},
        {keycap: Keycap.TEXT_CONTROL_100, x: 4.5, y: 5.5},
        {keycap: Keycap.TEXT_SHINE_100, x: 5.5, y: 5.5},
        {keycap: Keycap.TEXT_ALT_100, x: 6.5, y: 5.5},
        {keycap: Keycap.TEXT_FN, x: 7.5, y: 5.5},
        {keycap: Keycap.TEXT_HOME, x: 7.5, y: 6.5},
        {keycap: Keycap.TEXT_END, x: 7.5, y: 7.5},

        {keycap: Keycap.TEXT_PG_DN, x: 10, y: 7.5},
        {keycap: Keycap.TEXT_PG_UP, x: 10, y: 6.5},
        {keycap: Keycap.SPARKLE_SINGLE_WHITE, x: 10, y: 5.5},
        {keycap: Keycap.SPARKLE_DOUBLE_WHITE, x: 11, y: 5.5},
        {keycap: Keycap.SPARKLE_SINGLE_WHITE, x: 12, y: 5.5},
        {keycap: Keycap.SPARKLE_DOUBLE_WHITE, x: 13, y: 5.5},
        {keycap: Keycap.BLANK_200, x: 11, y: 6.5, rotation: 90},
        {keycap: Keycap.BLANK_200, x: 12, y: 6.5, rotation: 90},
        {keycap: Keycap.SPACEBAR_200, x: 13, y: 6.5, rotation: 90},
        {keycap: Keycap.SPACEBAR_200, x: 14, y: 6.5, rotation: 90},

        {keycap: Keycap.F_HOMING_SCOOPED, x: 16, y: 5.5},
        {keycap: Keycap.J_HOMING_SCOOPED, x: 17, y: 5.5},
    ],
};

const FOURTIES_ORTHO_KIT: KitDefinition = {
    name: KitName.FOURTIES_ORTHO,
    keycapWithPositions: [
        // comment below from sewing tin for posterity
        // frankly im amazed the negative x actually worked
        {keycap: Keycap.SPARKLE_SINGLE_BLUE, x: 0, y: -1.5},
        {keycap: Keycap.SPARKLE_DOUBLE_BLUE, x: 1, y: -1.5},
        {keycap: Keycap.SPARKLE_SINGLE_PURPLE, x: 2, y: -1.5},
        {keycap: Keycap.SPARKLE_DOUBLE_PURPLE, x: 3, y: -1.5},
        {keycap: Keycap.SPARKLE_SINGLE_PINK, x: 4, y: -1.5},
        {keycap: Keycap.SPARKLE_DOUBLE_PINK, x: 5, y: -1.5},
        {keycap: Keycap.SPARKLE_SINGLE_RED, x: 6, y: -1.5},
        {keycap: Keycap.SPARKLE_DOUBLE_RED, x: 7, y: -1.5},
        {keycap: Keycap.SPARKLE_SINGLE_ORANGE, x: 8, y: -1.5},
        {keycap: Keycap.SPARKLE_DOUBLE_ORANGE, x: 9, y: -1.5},
        {keycap: Keycap.SPARKLE_SINGLE_YELLOW, x: 10, y: -1.5},
        {keycap: Keycap.SPARKLE_DOUBLE_YELLOW, x: 11, y: -1.5},

        {keycap: Keycap.SPARKLE_SINGLE_BLUE, x: 0, y: 0},
        {keycap: Keycap.SPARKLE_DOUBLE_BLUE, x: 1, y: 0},
        {keycap: Keycap.SPARKLE_SINGLE_PURPLE, x: 2, y: 0},
        {keycap: Keycap.SPARKLE_DOUBLE_PURPLE, x: 3, y: 0},
        {keycap: Keycap.SPARKLE_SINGLE_PINK, x: 4, y: 0},
        {keycap: Keycap.SPARKLE_DOUBLE_PINK, x: 5, y: 0},
        {keycap: Keycap.SPARKLE_SINGLE_RED, x: 6, y: 0},
        {keycap: Keycap.SPARKLE_DOUBLE_RED, x: 7, y: 0},
        {keycap: Keycap.SPARKLE_SINGLE_ORANGE, x: 8, y: 0},
        {keycap: Keycap.SPARKLE_DOUBLE_ORANGE, x: 9, y: 0},
        {keycap: Keycap.SPARKLE_SINGLE_YELLOW, x: 10, y: 0},
        {keycap: Keycap.SPARKLE_DOUBLE_YELLOW, x: 11, y: 0},

        {keycap: Keycap.MULTISPARKLE_YELLOW_200, x: 12.5, y: 0},
        {keycap: Keycap.SPARKLE_DOUBLE_YELLOW_175, x: 14.5, y: 0},
        {keycap: Keycap.SPARKLE_DOUBLE_YELLOW_150, x: 16.25, y: 0},
        {keycap: Keycap.SPARKLE_SINGLE_YELLOW, x: 17.75, y: 0},
        {keycap: Keycap.SPARKLE_DOUBLE_ORANGE_125, x: 18.75, y: 0},

        {keycap: Keycap.TEXT_ESC, x: 0, y: 1},
        {keycap: Keycap.TEXT_BACK_100, x: 11, y: 1},

        {keycap: Keycap.TEXT_TAB_100, x: -1.5, y: 1},

        {keycap: Keycap.TEXT_BACKSPACE, x: 12.5, y: 1},
        {keycap: Keycap.TEXT_BACK_175, x: 14.5, y: 1},
        {keycap: Keycap.TEXT_BACK_150, x: 16.25, y: 1},
        {keycap: Keycap.PIPE_100, x: 17.75, y: 1},
        {keycap: Keycap.TEXT_BACK_125, x: 18.75, y: 1},

        {keycap: Keycap.Q, x: 1, y: 1},
        {keycap: Keycap.W, x: 2, y: 1},
        {keycap: Keycap.E, x: 3, y: 1},
        {keycap: Keycap.R, x: 4, y: 1},
        {keycap: Keycap.T, x: 5, y: 1},
        {keycap: Keycap.Y, x: 6, y: 1},
        {keycap: Keycap.U, x: 7, y: 1},
        {keycap: Keycap.I, x: 8, y: 1},
        {keycap: Keycap.O, x: 9, y: 1},
        {keycap: Keycap.P, x: 10, y: 1},

        {keycap: Keycap.TEXT_TAB_100, x: 0, y: 2},
        {keycap: Keycap.TEXT_ENTER_100, x: 11, y: 2},

        {keycap: Keycap.TEXT_TAB_125, x: -3.75, y: 2},
        {keycap: Keycap.TEXT_ESC, x: -2.5, y: 2},
        {keycap: Keycap.TEXT_CONTROL_100, x: -1.5, y: 2},

        {keycap: Keycap.TEXT_ENTER_175, x: 12.5, y: 2},
        {keycap: Keycap.TEXT_ENTER_150, x: 14.25, y: 2},
        {keycap: Keycap.TEXT_ENTER_125, x: 15.75, y: 2},
        {keycap: Keycap.SINGLE_QUOTE, x: 17, y: 2},

        {keycap: Keycap.A, x: 1, y: 2},
        {keycap: Keycap.S, x: 2, y: 2},
        {keycap: Keycap.D, x: 3, y: 2},
        {keycap: Keycap.F_HOMING_BARRED, x: 4, y: 2},
        {keycap: Keycap.G, x: 5, y: 2},
        {keycap: Keycap.H, x: 6, y: 2},
        {keycap: Keycap.J_HOMING_BARRED, x: 7, y: 2},
        {keycap: Keycap.K, x: 8, y: 2},
        {keycap: Keycap.L, x: 9, y: 2},
        {keycap: Keycap.SEMICOLON, x: 10, y: 2},

        {keycap: Keycap.TEXT_SHIFT_100, x: 0, y: 3},
        {keycap: Keycap.TEXT_SHIFT_100, x: 11, y: 3},

        {keycap: Keycap.TEXT_SHIFT_175, x: -3.75, y: 3},
        {keycap: Keycap.TEXT_SHIFT_150, x: -2, y: 3},

        {keycap: Keycap.TEXT_SHIFT_125, x: 12.5, y: 3},
        {keycap: Keycap.TEXT_ENTER_100, x: 13.75, y: 3},

        {keycap: Keycap.Z, x: 1, y: 3},
        {keycap: Keycap.X, x: 2, y: 3},
        {keycap: Keycap.C, x: 3, y: 3},
        {keycap: Keycap.V, x: 4, y: 3},
        {keycap: Keycap.B, x: 5, y: 3},
        {keycap: Keycap.N, x: 6, y: 3},
        {keycap: Keycap.M, x: 7, y: 3},
        {keycap: Keycap.COMMA, x: 8, y: 3},
        {keycap: Keycap.PERIOD, x: 9, y: 3},
        {keycap: Keycap.SLASH, x: 10, y: 3},

        {keycap: Keycap.TEXT_CONTROL_100, x: 0, y: 4},
        {keycap: Keycap.TEXT_SHINE_100, x: 1, y: 4},
        {keycap: Keycap.TEXT_ALT_100, x: 2, y: 4},
        {keycap: Keycap.TEXT_FN, x: 3, y: 4},
        {keycap: Keycap.TEXT_PG_UP, x: 4, y: 4},
        {keycap: Keycap.SPACEBAR_100, x: 5, y: 4},
        {keycap: Keycap.SPACEBAR_100, x: 6, y: 4},
        {keycap: Keycap.TEXT_PG_DN, x: 7, y: 4},
        {keycap: Keycap.ARROW_LEFT, x: 8, y: 4},
        {keycap: Keycap.ARROW_DOWN, x: 9, y: 4},
        {keycap: Keycap.ARROW_UP, x: 10, y: 4},
        {keycap: Keycap.ARROW_RIGHT, x: 11, y: 4},

        // {keycap: Keycap.TEXT_LOCK_175, x: 12.5, y: 4},

        {keycap: Keycap.F_HOMING_SCOOPED, x: 4, y: 6.5},
        {keycap: Keycap.J_HOMING_SCOOPED, x: 7, y: 6.5},

        {keycap: Keycap.TEXT_CONTROL_100, x: 0, y: 7.5},
        {keycap: Keycap.TEXT_SHINE_100, x: 1, y: 7.5},
        {keycap: Keycap.TEXT_ALT_100, x: 2, y: 7.5},
        {keycap: Keycap.TEXT_CONTROL_125, x: 3, y: 7.5},
        {keycap: Keycap.TEXT_CONTROL_125, x: 4.25, y: 7.5},
        {keycap: Keycap.TEXT_SHINE_125, x: 5.5, y: 7.5},
        {keycap: Keycap.TEXT_SHINE_125, x: 6.75, y: 7.5},
        {keycap: Keycap.TEXT_ALT_125, x: 8, y: 7.5},
        {keycap: Keycap.TEXT_ALT_125, x: 9.25, y: 7.5},
        {keycap: Keycap.TEXT_FN_125, x: 10.5, y: 7.5},

        {keycap: Keycap.TEXT_CONTROL_150, x: 0, y: 8.5},
        {keycap: Keycap.TEXT_SHINE_150, x: 1.5, y: 8.5},
        {keycap: Keycap.TEXT_ALT_150, x: 3, y: 8.5},
        {keycap: Keycap.TEXT_FN_150, x: 4.5, y: 8.5},

        {keycap: Keycap.BUTTERFLY, x: 12.5, y: 5.5},
        {keycap: Keycap.BISMUTH, x: 13.5, y: 5.5},
        {keycap: Keycap.IRIS_RIGHT_175, x: 12.5, y: 6.5},
        {keycap: Keycap.FEATHER_RIGHT_150, x: 14.25, y: 6.5},
        {keycap: Keycap.GEM_125, x: 15.75, y: 6.5},
        {keycap: Keycap.GEM, x: 17, y: 6.5},
        {keycap: Keycap.SHELL, x: 12.5, y: 7.5},
        {keycap: Keycap.SHELL, x: 13.5, y: 7.5},
        {keycap: Keycap.BUBBLE_125, x: 14.5, y: 7.5},
        {keycap: Keycap.BUBBLE_125, x: 15.75, y: 7.5},
        {keycap: Keycap.BUTTERFLY_SIDE_LEFT_150, x: 12.5, y: 8.5},
        {keycap: Keycap.BUTTERFLY_SIDE_RIGHT_150, x: 14, y: 8.5},
        {keycap: Keycap.IRIS_LEFT_175, x: 15.5, y: 8.5},

        {keycap: Keycap.SPACEBAR_150, x: 0, y: 10},
        {keycap: Keycap.SPACEBAR_150, x: 1.5, y: 10},
        {keycap: Keycap.SPACEBAR_175, x: 3, y: 10},
        {keycap: Keycap.SPACEBAR_175, x: 4.75, y: 10},
        {keycap: Keycap.SPACEBAR_200, x: 6.5, y: 10},
        {keycap: Keycap.SPACEBAR_200, x: 8.5, y: 10},

        {keycap: Keycap.SPACEBAR_225, x: 0, y: 11},
        {keycap: Keycap.SPACEBAR_225, x: 2.25, y: 11},
        {keycap: Keycap.SPACEBAR_275, x: 4.5, y: 11},
        {keycap: Keycap.SPACEBAR_625, x: 7.25, y: 11},
   ],
};


// Only used for kit renderer, may not need to update fully for Iridescent
export const AllKitDefinitions = {
    BASE_KIT,
    NUMPAD_KIT,
    ERGO_KIT,
    FOURTIES_ORTHO_KIT,
};
