;

// remember to update keycap_data when you add/change things here
export enum Keycap {
    /* Alphas */
    BACKTICK,
    ALPHA_1, ALPHA_2, ALPHA_3, ALPHA_4, ALPHA_5,
    ALPHA_6, ALPHA_7, ALPHA_8, ALPHA_9, ALPHA_0,
    ALPHA_DASH, ALPHA_EQUALS,
    Q, W, E, R, T, Y, U, I, O, P, LEFT_BRACKET, RIGHT_BRACKET, PIPE_150,
    A, S, D, F, G, H, J, K, L, SEMICOLON, SINGLE_QUOTE, 
    Z, X, C, V, B, N, M, COMMA, PERIOD, SLASH,

    F_HOMING_BARRED, J_HOMING_BARRED, 
    F_HOMING_SCOOPED, J_HOMING_SCOOPED, 

    /* Arrows */
    ARROW_UP,
    ARROW_LEFT,
    ARROW_DOWN,
    ARROW_RIGHT,

    /* Sparkles */
    SPARKLE_SINGLE_BLUE,
    SPARKLE_DOUBLE_BLUE,
    SPARKLE_SINGLE_PURPLE,
    SPARKLE_DOUBLE_PURPLE,
    SPARKLE_SINGLE_PINK,
    SPARKLE_DOUBLE_PINK,
    SPARKLE_SINGLE_RED,
    SPARKLE_DOUBLE_RED,
    SPARKLE_SINGLE_ORANGE,
    SPARKLE_DOUBLE_ORANGE,
    SPARKLE_SINGLE_YELLOW,
    SPARKLE_DOUBLE_YELLOW,
    SPARKLE_SINGLE_WHITE,
    SPARKLE_DOUBLE_WHITE,

    /* Novelties */
    // Note: choosing to not have separate versions for different rows for simplicity
    // Base kit
    MULTISPARKLE_YELLOW_200,
    BUTTERFLY,
    BISMUTH,
    GEM,
    FEATHER_RIGHT,
    BUBBLES_225,
    BUBBLE,
    SHELL,
    BUTTERFLY_SIDE_RIGHT_125,
    BUTTERFLY_SIDE_LEFT_125,
    IRIS_RIGHT_150,
    IRIS_LEFT_150,
    // Ergo
    SPARKLE_DOUBLE_BLUE_150,
    SPARKLE_SINGLE_YELLOW_150,
    FEATHER_LEFT,
    BUTTERFLY_SIDE_RIGHT_150,
    BUTTERFLY_SIDE_LEFT_150,
    BUBBLE_150_V,
    // 40s/Ortho
    SPARKLE_DOUBLE_YELLOW_175,
    SPARKLE_DOUBLE_YELLOW_150,
    SPARKLE_DOUBLE_ORANGE_125,
    IRIS_RIGHT_175,
    IRIS_LEFT_175,
    FEATHER_RIGHT_150,
    GEM_125,
    BUBBLE_125,
    // ISO
    ISO_BUBBLES,
    // Numpad,
    BUBBLES_200_V,

    /* TKL Text mods */
    TEXT_ESC,
    // TEXT_F1, TEXT_F2, TEXT_F3, TEXT_F4,
    // TEXT_F5, TEXT_F6, TEXT_F7, TEXT_F8,
    // TEXT_F9, TEXT_F10, TEXT_F11, TEXT_F12, TEXT_F13,

    // TEXT_PRT_SC, TEXT_SCROLL, TEXT_PAUSE, 
    TEXT_INSERT, TEXT_HOME, TEXT_PG_UP,
    TEXT_DELETE, TEXT_END, TEXT_PG_DN, 

    TEXT_BACKSPACE, TEXT_TAB, TEXT_CAPS_LOCK, TEXT_CAPS_STEPPED, TEXT_ENTER, TEXT_SHIFT_225, TEXT_SHIFT_275,
    TEXT_CONTROL_125, TEXT_SHINE_125, TEXT_ALT_125, TEXT_FN_125,

    /* Other text mods */ 
    TEXT_SHIFT_125, TEXT_SHIFT_175, TEXT_FN, 
    TEXT_CONTROL_150, TEXT_SHINE_150, TEXT_SHINE_100, TEXT_ALT_150, TEXT_ALT_100,
    TEXT_CONTROL_100, 
    TEXT_SHIFT_200,
    TEXT_CONTROL_175,

    /* Numpad */
    TEXT_NUM_LOCK, DIVIDE, MULTIPLY, NUMPAD_MINUS,
    NUMPAD_7, NUMPAD_8, NUMPAD_9, NUMPAD_PLUS_200,
    NUMPAD_4, NUMPAD_5, NUMPAD_6, 
    NUMPAD_1, NUMPAD_2, NUMPAD_3, TEXT_NUMPAD_ENTER,
    NUMPAD_0_200, NUMPAD_PERIOD,
    NUMPAD_0_100, NUMPAD_00, NUMPAD_PLUS_100, 
    NUMPAD_5_HOMING_BARRED,
    NUMPAD_5_HOMING_SCOOPED,
    NUMPAD_EQUALS,

    /* ISO */
    TEXT_ISO_ENTER,
    ISO_ANGLE_BRACKET,

    /* Tsangan */
    TEXT_BACK_150,

    /* Ergo */
    // Note: 1.5u tab, back, ctrl already already exist due to base kit, so don't need to be defined here
    TEXT_ENTER_150,
    TEXT_SHIFT_150,
    TEXT_FN_150, 
    TEXT_SINGLE_QUOTE_150,
    BLANK_200,

    /* Spacebars */
    SPACEBAR_100,
    SPACEBAR_125,
    SPACEBAR_150,
    SPACEBAR_175,
    SPACEBAR_200,
    SPACEBAR_225,
    SPACEBAR_275,
    SPACEBAR_300,
    SPACEBAR_600,
    SPACEBAR_625,
    SPACEBAR_700,

    /* 40s / Ortho */
    QWERTY_ESC,
    TEXT_BACK_100,
    TEXT_BACK_125,
    // TEXT_BACK_150 exists in hhkb
    TEXT_BACK_175,
    TEXT_TAB_100,
    TEXT_TAB_125,
    TEXT_ENTER_100,
    TEXT_ENTER_125,
    // TEXT_ENTER_150 exists in ergo
    TEXT_ENTER_175,
    TEXT_SHIFT_100,
    // TEXT_SHIFT_125 exists in base for iso
    // TEXT_SHIFT_150 exists in ergo
    // TEXT_SHIFT_175 exists in hhkb 
    // TEXT_LOCK_175, - replaced by nov
    // These are the same visually as TEXT_PG_UP/TEXT_PG_DOWN, but separated so they don't get muddled in overrides etc
    TEXT_UP,
    TEXT_DOWN,

    PIPE_100,
};


// all stitches: 100, 125, 150, 175, 225, 275 
// 100/125 same, 150/175 same, 225/275 same?