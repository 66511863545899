import React from 'react';
import { Keycap } from './data/keyset';
import { getKeycapData, getKeycapImageUrl, HomingTypes, KeycapColourInfo, KeycapData } from './data/keycap_data';
import { KeycapWithPosition} from './data/types';

export const KEYCAP_SIZE = 52;

type KeycapRendererProps = KeycapWithPosition & {
    shouldShowLabel?: boolean;
};


// arbitrary rotation: Not Supported™️
function getTransformForKeycap(rotation: 90 | 180 | 270): string {
    let translate = '';
    if (rotation === 90) translate = `translate(0, -100%)`;
    if (rotation === 270) translate = `translate(-100%)`;

    return `rotate(${rotation}deg) ${translate}`;
}

function getInnerStylesForKeycap(keycapData: KeycapData): {
    keycapInnerStyles: React.CSSProperties,
    keycapTopStyles: React.CSSProperties, 
    keycapMaskStyles: React.CSSProperties,
    keycapLabelStyles: React.CSSProperties,
    keycapHomingBarStyles: React.CSSProperties,
} {
    const keycapColour = KeycapColourInfo.get(keycapData.color);
    if (!keycapColour) throw new Error(`unexpectedly missing keycapColour ${keycapData.color}`);
    const keycapInnerStyles = {
        backgroundColor: keycapColour.shadow,
        // borderColor: keycapColour.shadow, 
    }
    const keycapTopStyles = {
        backgroundColor: keycapColour.base,
        ...(keycapData.topSize !== undefined? {
            // can't have stepped 1us but meh
            width: `${keycapData.topSize/(keycapData.size ?? 1)*100}%`,
        } : {})
    };


    const keycapMaskRotation = keycapData.imageRotation !== undefined ? {
        transform: `rotate(${keycapData.imageRotation}deg)`,
    } : {};

    // Note: it might be simpler to just always mask...
    const keycapMaskStyles = keycapData.imageName !== undefined ? (
        keycapColour.legend ? {
            maskImage: `url(${getKeycapImageUrl(keycapData.imageName)})`,
            WebkitMaskImage: `url(${getKeycapImageUrl(keycapData.imageName)})`,
            backgroundColor: keycapColour.legend,
            ...keycapMaskRotation,
        }: {
            'backgroundImage': `url(${getKeycapImageUrl(keycapData.imageName)})`,
            ...keycapMaskRotation,
        }): {};

    const keycapLabelStyles = {
        color: keycapColour.legend ?? keycapColour.label ?? 'white',
    }

    const keycapHomingBarStyles = {
        // backgroundColor: keycapColour.shadow,
        // hack: better visibility, assumes all homing keys are white
        backgroundColor: '#eeeeee',
    }

    return {
        keycapInnerStyles,
        keycapTopStyles,
        keycapMaskStyles,
        keycapLabelStyles,
        keycapHomingBarStyles,
    };
}

function IsoEnter({keycapData, x, y}: {keycapData: KeycapData, x: number, y: number}) {
    const keycapStyles = {
        left: KEYCAP_SIZE * x,
        top: KEYCAP_SIZE * y,
        width: KEYCAP_SIZE * 1.5,
        height: KEYCAP_SIZE * 2,
        // rotation not supported at this time wahaha
    };

    const {keycapInnerStyles, keycapTopStyles, keycapMaskStyles} = getInnerStylesForKeycap(keycapData);
    
    // mega hack
    // need to update number here if default padding changes
    const KEYCAP_PADDING = 2;
    const KEYCAP_INNER_PADDING = 7;
    return (
        <div className="keycap" style={keycapStyles}>
            <div className="keycapInner" style={{
                ...keycapInnerStyles, 
                width: KEYCAP_SIZE * 1.25 - KEYCAP_PADDING * 2, 
                height: KEYCAP_SIZE * 2 - KEYCAP_PADDING * 2,
                float: 'right',
            }}>
                <div className="keycapTop" style={keycapTopStyles} />
            </div>
            <div className="keycapInner" style={{
                ...keycapInnerStyles, 
                height: KEYCAP_SIZE - KEYCAP_PADDING * 2,
                paddingBottom: 3, // hack for iso bubble enter so legend doesnt get cut off 
            }}>
                <div className="keycapTop" style={{
                    ...keycapTopStyles,
                    // hack...wahoo 
                    position: 'relative',
                    zIndex: 100,
                }} />
            </div>
            <div className="keycapMask" style ={{
                // I have some regrets
                // also this wont work for non blue iso enters good thing they dont exist ha ha ha
                ...keycapMaskStyles,
                position: 'absolute',
                top: KEYCAP_PADDING + KEYCAP_INNER_PADDING,
                left: KEYCAP_PADDING + KEYCAP_INNER_PADDING,
                width: KEYCAP_SIZE * 1.5 - (KEYCAP_PADDING + KEYCAP_INNER_PADDING) * 2, 
                height: KEYCAP_SIZE * 2 - (KEYCAP_PADDING + KEYCAP_INNER_PADDING) * 2,
                zIndex: 101,
            }} />
        </div>
    );

}

export function KeycapRenderer({keycap, x, y, rotation, shouldShowLabel}: KeycapRendererProps) {
    const keycapData = getKeycapData(keycap);

    if (keycapData.isIsoEnter) {
        return <IsoEnter keycapData={keycapData} x={x} y={y} />;
    }

    const keycapStyles = {
        left: KEYCAP_SIZE * x,
        top: KEYCAP_SIZE * y,
        width: KEYCAP_SIZE * (keycapData.size ?? 1),
        height: KEYCAP_SIZE,
        ...(rotation !== undefined ? {
            transform: getTransformForKeycap(rotation),
            transformOrigin: rotation === 180 ? 'center' : 'top left',
        } : {}),
        // HACK: "solve" the UK ISO / alt numrow issue by z-indexing 
        // TODO(iridescent): might be able to remove this hack now
        ...(keycap === Keycap.TEXT_ESC ? {
            zIndex: 10,
        } : {})
    };

    // hack: just go for consistency rather than try to get them all in the right corner
    // todo: fix this properly?
    const keycapLabelPositionStyles = rotation === 180 ? {
        top: '4px',
        left: '4px',
        transform: getTransformForKeycap(180),
    }: rotation === 270 ? {
        top: '4px',
        left: '4px',
        transform: getTransformForKeycap(180),
    } :{};

    const {keycapInnerStyles, keycapTopStyles, keycapMaskStyles, keycapLabelStyles, keycapHomingBarStyles} = getInnerStylesForKeycap(keycapData);

    // hack: heuristic that all blanks are curved, don't show size for non-curved 1u
    const keycapLabelUnit = keycapData.imageName !== undefined ? 'u' : 'c';
    let keycapLabel;
    if (keycapData.homingType) {
        // TODO: revisit this?
        // keycapLabel = 'h';
    } else if (keycapData.size !== undefined || keycapLabelUnit === 'c') {
        keycapLabel = `${(keycapData.size ?? 1).toFixed(2)}${keycapLabelUnit}`;
    }

    return (
        <div className="keycap" style={keycapStyles}>
            <div className="keycapInner" style={keycapInnerStyles}>
                {/* <div className="keycapBezel" /> */}
                {keycapData.homingType === HomingTypes.SCOOPED && <div className="keycapHomingScoop" />}
                <div className="keycapTop" style={keycapTopStyles}>
                    <div className="keycapMask" style={keycapMaskStyles} />
                    {keycapData.homingType === HomingTypes.BARRED && <div className="keycapHomingBar" style={keycapHomingBarStyles} />}
                    {/* {Keycap[keycap]} */}
                </div>
                {shouldShowLabel && keycapLabel && <div className="keycapLabel" style={{...keycapLabelStyles, ...keycapLabelPositionStyles}}>{keycapLabel}</div>}
            </div>
        </div>
    );
}