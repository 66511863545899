import {Keycap} from './keyset';

export enum KeycapColourNames {
    WHITE,
    MULTICOLOUR_NOV,
    BLUE,
    PURPLE,
    RED,
    PINK,
    ORANGE,
    YELLOW
};

export type KeycapColour = {
    base: string;
    shadow: string;
    legend?: string;
    label?: string;
};

export const KeycapColourInfo: Map<KeycapColourNames, KeycapColour> = new Map([
    [KeycapColourNames.WHITE, {base: '#FFFFFF', shadow: '#F5F5F5', legend: '#7A93DC'}],
    // No legend colour = displays legend png as is
    [KeycapColourNames.MULTICOLOUR_NOV, {base: '#FFFFFF', shadow: '#F5F5F5', label: '#7A93DC'}],
    [KeycapColourNames.BLUE, {base: '#94B3EA', shadow: '#7B9CDD'}],
    [KeycapColourNames.PURPLE, {base: '#B9A3E3', shadow: '#A68FD8'}],
    [KeycapColourNames.PINK, {base: '#EDB7E8', shadow: '#DBA9D8'}],
    [KeycapColourNames.RED, {base: '#F9B0CC', shadow: '#EE9EBA'}],
    [KeycapColourNames.ORANGE, {base: '#FFCA9D', shadow: '#EDB98E'}],
    [KeycapColourNames.YELLOW, {base: '#FFEC9F', shadow: '#F0DF98'}],
]);

enum KeycapImageNames {
    /* novelties */
    SPARKLE_SINGLE,
    SPARKLE_DOUBLE,
    SPARKLE_MULTI,
    BISMUTH,
    FEATHER_RIGHT,
    FEATHER_LEFT,
    BUTTERFLY,
    BUTTERFLY_SIDE_RIGHT,
    BUTTERFLY_SIDE_LEFT,
    SHELL,
    BUBBLE,
    GEM,
    BUBBLE_ENTER,
    IRIS_RIGHT,
    IRIS_LEFT,
    BUBBLES,
    ISO_BUBBLES,
    NUMPAD_BUBBLES,

    /* alphas */
    BACKTICK,
    ALPHA_1,
    ALPHA_2,
    ALPHA_3,
    ALPHA_4,
    ALPHA_5,
    ALPHA_6,
    ALPHA_7,
    ALPHA_8,
    ALPHA_9,
    ALPHA_0,
    PIPE,
    ALPHA_DASH,
    ALPHA_EQUALS,
    Q,
    W,
    E,
    R,
    T,
    Y,
    U,
    I,
    O,
    P,
    LEFT_BRACKET,
    RIGHT_BRACKET,
    A,
    S,
    D,
    F,
    G,
    H,
    J,
    K,
    L,
    SEMICOLON,
    SINGLE_QUOTE,
    Z,
    X,
    C,
    V,
    B,
    N,
    M,
    COMMA,
    PERIOD,
    SLASH,

    /* iso alphas */
    ISO_ANGLE_BRACKET,

    /* arrow (rely on rotation?) */
    UP_ARROW,

    /* text mods */
    BACKSPACE,
    BACK, // HHKB
    ESC,
    TAB,
    CAPS,
    ENTER,
    ENTER_ISO,
    SHIFT,
    CTRL,
    SHINE,
    ALT,
    // MENU, 
    // F1,
    // F2,
    // F3,
    // F4,
    // F5,
    // F6,
    // F7,
    // F8,
    // F9,
    // F10,
    // F11,
    // F12,
    // F13,
    FN,
    // PRINT,
    // SCROLL,
    // BREAK,
    INS,
    HOME,
    UP,
    DEL,
    END,
    DOWN,
    LOCK,

    /* numpad */
    NUMPAD_1,
    NUMPAD_2,
    NUMPAD_3,
    NUMPAD_4,
    NUMPAD_5,
    NUMPAD_6,
    NUMPAD_7,
    NUMPAD_8,
    NUMPAD_9,
    NUMPAD_0,
    NUM,
    NUMPAD_DIVIDE,
    NUMPAD_MULTIPLY,
    NUMPAD_MINUS,
    NUMPAD_PLUS,
    NUMPAD_PERIOD,
    NUMPAD_00,
    NUMPAD_EQUALS,

    // TODO: actually have isConvex instead of this hack for spacebar identification/concave blanks
    BLANK,
}

export enum HomingTypes {
    BARRED,
    SCOOPED,
}

export type KeycapData = {
    color: KeycapColourNames;
    imageName?: KeycapImageNames;
    // default = 1u if not specified, for brevity
    size?: 1.25 | 1.5 | 1.75 | 2 | 2.25 | 2.75 | 3 | 6 | 6.25 | 7;
    // for stepped capslock
    topSize?: 1.25;
    // clockwise? default = 0 if not specified
    // note: separate to LayoutKey rotation, this is image rotation (confusingly!!!)
    imageRotation?: 90 | 180 | 270;
    isIsoEnter?: true, // should only be set when size is not set
    homingType?: HomingTypes, // should only be set when size is not set
};

export function getKeycapImageUrl(keycapImageName: KeycapImageNames) {
    return `/keycap_images/${KeycapImageNames[keycapImageName].toLowerCase()}.png`;
}

export function preloadAllImages() {
    // hack, not sure how you're supposed to do this
    for (const enumItem of Object.keys(KeycapImageNames)) {
        const maybeNumber = Number(enumItem);
        if (!Number.isNaN(maybeNumber)) {
            // console.log('preloading', maybeNumber);
            new Image().src = getKeycapImageUrl(maybeNumber);
        }
    }
}

// this probably should've just extracted the right data from the keycap name instead. ah well
export function getKeycapData(keycap: Keycap): KeycapData {
    switch(keycap) {
        /* Alphas */
        case Keycap.BACKTICK: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.BACKTICK};
        case Keycap.ALPHA_1: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.ALPHA_1};
        case Keycap.ALPHA_2: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.ALPHA_2};
        case Keycap.ALPHA_3: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.ALPHA_3};
        case Keycap.ALPHA_4: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.ALPHA_4};
        case Keycap.ALPHA_5: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.ALPHA_5};
        case Keycap.ALPHA_6: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.ALPHA_6};
        case Keycap.ALPHA_7: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.ALPHA_7};
        case Keycap.ALPHA_8: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.ALPHA_8};
        case Keycap.ALPHA_9: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.ALPHA_9};
        case Keycap.ALPHA_0: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.ALPHA_0};
        case Keycap.ALPHA_DASH: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.ALPHA_DASH};
        case Keycap.ALPHA_EQUALS: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.ALPHA_EQUALS};
        case Keycap.Q: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.Q};
        case Keycap.W: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.W};
        case Keycap.E: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.E};
        case Keycap.R: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.R};
        case Keycap.T: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.T};
        case Keycap.Y: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.Y};
        case Keycap.U: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.U};
        case Keycap.I: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.I};
        case Keycap.O: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.O};
        case Keycap.P: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.P};
        case Keycap.LEFT_BRACKET: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.LEFT_BRACKET};
        case Keycap.RIGHT_BRACKET: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.RIGHT_BRACKET};
        case Keycap.PIPE_150: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.PIPE, size: 1.5};
        case Keycap.A: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.A};
        case Keycap.S: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.S};
        case Keycap.D: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.D};
        case Keycap.F: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.F};
        case Keycap.G: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.G};
        case Keycap.H: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.H};
        case Keycap.J: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.J};
        case Keycap.K: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.K};
        case Keycap.L: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.L};
        case Keycap.SEMICOLON: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.SEMICOLON};
        case Keycap.SINGLE_QUOTE: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.SINGLE_QUOTE};
        case Keycap.Z: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.Z};
        case Keycap.X: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.X};
        case Keycap.C: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.C};
        case Keycap.V: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.V};
        case Keycap.B: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.B};
        case Keycap.N: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.N};
        case Keycap.M: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.M};
        case Keycap.COMMA: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.COMMA};
        case Keycap.PERIOD: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.PERIOD};
        case Keycap.SLASH: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.SLASH};

        case Keycap.F_HOMING_BARRED: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.F, homingType: HomingTypes.BARRED};
        case Keycap.J_HOMING_BARRED: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.J, homingType: HomingTypes.BARRED};
        case Keycap.F_HOMING_SCOOPED: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.F, homingType: HomingTypes.SCOOPED};
        case Keycap.J_HOMING_SCOOPED: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.J, homingType: HomingTypes.SCOOPED};

        /* Arrows */
        case Keycap.ARROW_UP: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.UP_ARROW};
        case Keycap.ARROW_LEFT: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.UP_ARROW, imageRotation: 270};
        case Keycap.ARROW_DOWN: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.UP_ARROW, imageRotation: 180};
        case Keycap.ARROW_RIGHT: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.UP_ARROW, imageRotation: 90};

        /* Sparkles */
        case Keycap.SPARKLE_SINGLE_BLUE: return {color: KeycapColourNames.BLUE, imageName: KeycapImageNames.SPARKLE_SINGLE};
        case Keycap.SPARKLE_DOUBLE_BLUE: return {color: KeycapColourNames.BLUE, imageName: KeycapImageNames.SPARKLE_DOUBLE};
        case Keycap.SPARKLE_SINGLE_PURPLE: return {color: KeycapColourNames.PURPLE, imageName: KeycapImageNames.SPARKLE_SINGLE};
        case Keycap.SPARKLE_DOUBLE_PURPLE: return {color: KeycapColourNames.PURPLE, imageName: KeycapImageNames.SPARKLE_DOUBLE};
        case Keycap.SPARKLE_SINGLE_PINK: return {color: KeycapColourNames.PINK, imageName: KeycapImageNames.SPARKLE_SINGLE};
        case Keycap.SPARKLE_DOUBLE_PINK: return {color: KeycapColourNames.PINK, imageName: KeycapImageNames.SPARKLE_DOUBLE};
        case Keycap.SPARKLE_SINGLE_RED: return {color: KeycapColourNames.RED, imageName: KeycapImageNames.SPARKLE_SINGLE};
        case Keycap.SPARKLE_DOUBLE_RED: return {color: KeycapColourNames.RED, imageName: KeycapImageNames.SPARKLE_DOUBLE};
        case Keycap.SPARKLE_SINGLE_ORANGE: return {color: KeycapColourNames.ORANGE, imageName: KeycapImageNames.SPARKLE_SINGLE};
        case Keycap.SPARKLE_DOUBLE_ORANGE: return {color: KeycapColourNames.ORANGE, imageName: KeycapImageNames.SPARKLE_DOUBLE};
        case Keycap.SPARKLE_SINGLE_YELLOW: return {color: KeycapColourNames.YELLOW, imageName: KeycapImageNames.SPARKLE_SINGLE};
        case Keycap.SPARKLE_DOUBLE_YELLOW: return {color: KeycapColourNames.YELLOW, imageName: KeycapImageNames.SPARKLE_DOUBLE};
        case Keycap.SPARKLE_SINGLE_WHITE: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.SPARKLE_SINGLE};
        case Keycap.SPARKLE_DOUBLE_WHITE: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.SPARKLE_DOUBLE};

        /* Novelties */
        // Base kit
        case Keycap.MULTISPARKLE_YELLOW_200: return {color: KeycapColourNames.YELLOW, imageName: KeycapImageNames.SPARKLE_MULTI, size: 2};
        case Keycap.BUTTERFLY: return {color: KeycapColourNames.MULTICOLOUR_NOV, imageName: KeycapImageNames.BUTTERFLY};
        case Keycap.BISMUTH: return {color: KeycapColourNames.MULTICOLOUR_NOV, imageName: KeycapImageNames.BISMUTH};
        case Keycap.GEM: return {color: KeycapColourNames.MULTICOLOUR_NOV, imageName: KeycapImageNames.GEM};
        case Keycap.FEATHER_RIGHT: return {color: KeycapColourNames.MULTICOLOUR_NOV, imageName: KeycapImageNames.FEATHER_RIGHT};
        case Keycap.BUBBLES_225: return {color: KeycapColourNames.MULTICOLOUR_NOV, imageName: KeycapImageNames.BUBBLES, size: 2.25};
        case Keycap.BUBBLE: return {color: KeycapColourNames.MULTICOLOUR_NOV, imageName: KeycapImageNames.BUBBLE};
        case Keycap.SHELL: return {color: KeycapColourNames.MULTICOLOUR_NOV, imageName: KeycapImageNames.SHELL};
        case Keycap.BUTTERFLY_SIDE_RIGHT_125: return {color: KeycapColourNames.MULTICOLOUR_NOV, imageName: KeycapImageNames.BUTTERFLY_SIDE_RIGHT, size: 1.25};
        case Keycap.BUTTERFLY_SIDE_LEFT_125: return {color: KeycapColourNames.MULTICOLOUR_NOV, imageName: KeycapImageNames.BUTTERFLY_SIDE_LEFT, size: 1.25};
        case Keycap.IRIS_RIGHT_150: return {color: KeycapColourNames.MULTICOLOUR_NOV, imageName: KeycapImageNames.IRIS_RIGHT, size: 1.5};
        case Keycap.IRIS_LEFT_150: return {color: KeycapColourNames.MULTICOLOUR_NOV, imageName: KeycapImageNames.IRIS_LEFT, size: 1.5};
        // Ergo specific
        case Keycap.SPARKLE_DOUBLE_BLUE_150: return {color: KeycapColourNames.BLUE, imageName: KeycapImageNames.SPARKLE_DOUBLE, size: 1.5};
        case Keycap.SPARKLE_SINGLE_YELLOW_150: return {color: KeycapColourNames.YELLOW, imageName: KeycapImageNames.SPARKLE_SINGLE, size: 1.5};
        case Keycap.FEATHER_LEFT: return {color: KeycapColourNames.MULTICOLOUR_NOV, imageName: KeycapImageNames.FEATHER_LEFT};
        case Keycap.BUTTERFLY_SIDE_RIGHT_150: return {color: KeycapColourNames.MULTICOLOUR_NOV, imageName: KeycapImageNames.BUTTERFLY_SIDE_RIGHT, size: 1.5};
        case Keycap.BUTTERFLY_SIDE_LEFT_150: return {color: KeycapColourNames.MULTICOLOUR_NOV, imageName: KeycapImageNames.BUTTERFLY_SIDE_LEFT, size: 1.5};
        case Keycap.BUBBLE_150_V: return {color: KeycapColourNames.MULTICOLOUR_NOV, imageName: KeycapImageNames.BUBBLE, size: 1.5, imageRotation: 90};
        // 40s/Ortho
        case Keycap.SPARKLE_DOUBLE_YELLOW_175: return {color: KeycapColourNames.YELLOW, imageName: KeycapImageNames.SPARKLE_DOUBLE, size: 1.75}; 
        case Keycap.SPARKLE_DOUBLE_YELLOW_150: return {color: KeycapColourNames.YELLOW, imageName: KeycapImageNames.SPARKLE_DOUBLE, size: 1.5};
        case Keycap.SPARKLE_DOUBLE_ORANGE_125: return {color: KeycapColourNames.ORANGE, imageName: KeycapImageNames.SPARKLE_DOUBLE, size: 1.25}; 
        case Keycap.IRIS_RIGHT_175: return {color: KeycapColourNames.MULTICOLOUR_NOV, imageName: KeycapImageNames.IRIS_RIGHT, size: 1.75}; 
        case Keycap.IRIS_LEFT_175: return {color: KeycapColourNames.MULTICOLOUR_NOV, imageName: KeycapImageNames.IRIS_LEFT, size: 1.75}; 
        case Keycap.FEATHER_RIGHT_150: return {color: KeycapColourNames.MULTICOLOUR_NOV, imageName: KeycapImageNames.FEATHER_RIGHT, size: 1.50}; 
        case Keycap.GEM_125: return {color: KeycapColourNames.MULTICOLOUR_NOV, imageName: KeycapImageNames.GEM, size: 1.25};
        case Keycap.BUBBLE_125: return {color: KeycapColourNames.MULTICOLOUR_NOV, imageName: KeycapImageNames.BUBBLE, size: 1.25};
        // ISO
        case Keycap.ISO_BUBBLES: return {color: KeycapColourNames.MULTICOLOUR_NOV, imageName: KeycapImageNames.ISO_BUBBLES, isIsoEnter: true};
        // Numpad
        case Keycap.BUBBLES_200_V: return {color: KeycapColourNames.MULTICOLOUR_NOV, imageName: KeycapImageNames.NUMPAD_BUBBLES, size: 2};

        /* TKL Text mods */
        case Keycap.TEXT_ESC: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.ESC};
        // case Keycap.TEXT_F1: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.F1};
        // case Keycap.TEXT_F2: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.F2};
        // case Keycap.TEXT_F3: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.F3};
        // case Keycap.TEXT_F4: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.F4};
        // case Keycap.TEXT_F5: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.F5};
        // case Keycap.TEXT_F6: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.F6};
        // case Keycap.TEXT_F7: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.F7};
        // case Keycap.TEXT_F8: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.F8};
        // case Keycap.TEXT_F9: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.F9};
        // case Keycap.TEXT_F10: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.F10};
        // case Keycap.TEXT_F11: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.F11};
        // case Keycap.TEXT_F12: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.F12};
        // case Keycap.TEXT_F13: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.F13};
    
        // case Keycap.TEXT_PRT_SC: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.PRINT};
        // case Keycap.TEXT_SCROLL: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.SCROLL};
        // case Keycap.TEXT_PAUSE: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.BREAK};

        case Keycap.TEXT_INSERT: return {color:KeycapColourNames.WHITE, imageName: KeycapImageNames.INS};
        case Keycap.TEXT_HOME: return {color:KeycapColourNames.WHITE, imageName: KeycapImageNames.HOME};
        case Keycap.TEXT_PG_UP: return {color:KeycapColourNames.WHITE, imageName: KeycapImageNames.UP};
        case Keycap.TEXT_DELETE: return {color:KeycapColourNames.WHITE, imageName: KeycapImageNames.DEL};
        case Keycap.TEXT_END: return {color:KeycapColourNames.WHITE, imageName: KeycapImageNames.END};
        case Keycap.TEXT_PG_DN: return {color:KeycapColourNames.WHITE, imageName: KeycapImageNames.DOWN};

        case Keycap.TEXT_BACKSPACE: return {color:KeycapColourNames.WHITE, imageName: KeycapImageNames.BACKSPACE, size: 2};
        case Keycap.TEXT_TAB: return {color:KeycapColourNames.WHITE, imageName: KeycapImageNames.TAB, size: 1.5};
        case Keycap.TEXT_CAPS_LOCK: return {color:KeycapColourNames.WHITE, imageName: KeycapImageNames.CAPS, size: 1.75};
        case Keycap.TEXT_CAPS_STEPPED: return {color:KeycapColourNames.WHITE, imageName: KeycapImageNames.CAPS, size: 1.75, topSize: 1.25};
        case Keycap.TEXT_ENTER: return {color:KeycapColourNames.WHITE, imageName: KeycapImageNames.ENTER, size: 2.25}; 
        case Keycap.TEXT_SHIFT_225: return {color:KeycapColourNames.WHITE, imageName: KeycapImageNames.SHIFT, size: 2.25};
        case Keycap.TEXT_SHIFT_275: return {color:KeycapColourNames.WHITE, imageName: KeycapImageNames.SHIFT, size: 2.75};

        case Keycap.TEXT_CONTROL_125: return {color:KeycapColourNames.WHITE, imageName: KeycapImageNames.CTRL, size: 1.25};
        case Keycap.TEXT_SHINE_125: return {color:KeycapColourNames.WHITE, imageName: KeycapImageNames.SHINE, size: 1.25};
        case Keycap.TEXT_ALT_125: return {color:KeycapColourNames.WHITE, imageName: KeycapImageNames.ALT, size: 1.25};
        case Keycap.TEXT_FN_125: return {color:KeycapColourNames.WHITE, imageName: KeycapImageNames.FN, size: 1.25};
    
        /* Other text mods */ 
        case Keycap.TEXT_SHIFT_125: return {color:KeycapColourNames.WHITE, imageName: KeycapImageNames.SHIFT, size: 1.25};
        case Keycap.TEXT_SHIFT_175: return {color:KeycapColourNames.WHITE, imageName: KeycapImageNames.SHIFT, size: 1.75};
        case Keycap.TEXT_FN: return {color:KeycapColourNames.WHITE, imageName: KeycapImageNames.FN};
        case Keycap.TEXT_SHIFT_200: return {color:KeycapColourNames.WHITE, imageName: KeycapImageNames.SHIFT, size: 2};

        case Keycap.TEXT_CONTROL_150: return {color:KeycapColourNames.WHITE, imageName: KeycapImageNames.CTRL, size: 1.5};
        case Keycap.TEXT_SHINE_150: return {color:KeycapColourNames.WHITE, imageName: KeycapImageNames.SHINE, size: 1.5};
        case Keycap.TEXT_SHINE_100: return {color:KeycapColourNames.WHITE, imageName: KeycapImageNames.SHINE};
        case Keycap.TEXT_ALT_150: return {color:KeycapColourNames.WHITE, imageName: KeycapImageNames.ALT, size: 1.5};
        case Keycap.TEXT_ALT_100: return {color:KeycapColourNames.WHITE, imageName: KeycapImageNames.ALT};
        case Keycap.TEXT_CONTROL_100: return {color:KeycapColourNames.WHITE, imageName: KeycapImageNames.CTRL};
        case Keycap.TEXT_CONTROL_175: return {color:KeycapColourNames.WHITE, imageName: KeycapImageNames.CTRL, size: 1.75};

        /* Numpad */
        case Keycap.TEXT_NUM_LOCK: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.NUM};
        case Keycap.DIVIDE: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.NUMPAD_DIVIDE};
        case Keycap.MULTIPLY: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.NUMPAD_MULTIPLY};
        case Keycap.NUMPAD_MINUS: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.NUMPAD_MINUS};

        case Keycap.NUMPAD_1: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.NUMPAD_1};
        case Keycap.NUMPAD_2: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.NUMPAD_2};
        case Keycap.NUMPAD_3: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.NUMPAD_3};
        case Keycap.NUMPAD_4: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.NUMPAD_4};
        case Keycap.NUMPAD_5: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.NUMPAD_5};
        case Keycap.NUMPAD_6: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.NUMPAD_6};
        case Keycap.NUMPAD_7: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.NUMPAD_7};
        case Keycap.NUMPAD_8: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.NUMPAD_8};
        case Keycap.NUMPAD_9: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.NUMPAD_9};
        case Keycap.NUMPAD_0_100: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.NUMPAD_0};
        case Keycap.NUMPAD_0_200: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.NUMPAD_0, size: 2};
        case Keycap.NUMPAD_PERIOD: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.NUMPAD_PERIOD};
        case Keycap.NUMPAD_00: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.NUMPAD_00};

        case Keycap.NUMPAD_5_HOMING_BARRED: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.NUMPAD_5, homingType: HomingTypes.BARRED};
        case Keycap.NUMPAD_5_HOMING_SCOOPED: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.NUMPAD_5, homingType: HomingTypes.SCOOPED};

        case Keycap.NUMPAD_PLUS_200: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.NUMPAD_PLUS, size: 2};
        case Keycap.NUMPAD_PLUS_100: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.NUMPAD_PLUS};
        // hack: imageRotation used to offset the rotation of the entire key since this is always displayed vertically
        case Keycap.TEXT_NUMPAD_ENTER: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.ENTER, size: 2, imageRotation: 270};
        case Keycap.NUMPAD_EQUALS: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.NUMPAD_EQUALS};

        /* ISO */
        case Keycap.TEXT_ISO_ENTER: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.ENTER_ISO, isIsoEnter: true};
        case Keycap.ISO_ANGLE_BRACKET: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.ISO_ANGLE_BRACKET};
    
        /* Tsangan */
        case Keycap.TEXT_BACK_150: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.BACK, size: 1.5};
    
        /* Ergo */
        case Keycap.BLANK_200: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.BLANK, size: 2};
        case Keycap.TEXT_ENTER_150: return {color:KeycapColourNames.WHITE, imageName: KeycapImageNames.ENTER, size: 1.5}; 
        case Keycap.TEXT_SHIFT_150: return {color:KeycapColourNames.WHITE, imageName: KeycapImageNames.SHIFT, size: 1.5}; 
        case Keycap.TEXT_FN_150: return {color:KeycapColourNames.WHITE, imageName: KeycapImageNames.FN, size: 1.5}; 
        case Keycap.TEXT_SINGLE_QUOTE_150: return {color:KeycapColourNames.WHITE, imageName: KeycapImageNames.SINGLE_QUOTE, size: 1.5}; 

        /* Spacebars */
        case Keycap.SPACEBAR_100: return {color: KeycapColourNames.WHITE};
        case Keycap.SPACEBAR_125: return {color: KeycapColourNames.WHITE, size: 1.25};
        case Keycap.SPACEBAR_150: return {color: KeycapColourNames.WHITE, size: 1.50};
        case Keycap.SPACEBAR_175: return {color: KeycapColourNames.WHITE, size: 1.75};
        case Keycap.SPACEBAR_200: return {color: KeycapColourNames.WHITE, size: 2};
        case Keycap.SPACEBAR_225: return {color: KeycapColourNames.WHITE, size: 2.25};
        case Keycap.SPACEBAR_275: return {color: KeycapColourNames.WHITE, size: 2.75};
        case Keycap.SPACEBAR_300: return {color: KeycapColourNames.WHITE, size: 3};
        case Keycap.SPACEBAR_600: return {color: KeycapColourNames.WHITE, size: 6};
        case Keycap.SPACEBAR_625: return {color: KeycapColourNames.WHITE, size: 6.25};
        case Keycap.SPACEBAR_700: return {color: KeycapColourNames.WHITE, size: 7}
   
        /* 40s / Ortho */
        case Keycap.TEXT_BACK_100: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.BACK};
        case Keycap.TEXT_BACK_125: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.BACK, size: 1.25};
        case Keycap.TEXT_BACK_175: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.BACK, size: 1.75};
        case Keycap.TEXT_TAB_100: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.TAB};
        case Keycap.TEXT_TAB_125: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.TAB, size: 1.25};
        case Keycap.TEXT_ENTER_100: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.ENTER};
        case Keycap.TEXT_ENTER_125: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.ENTER, size: 1.25};
        case Keycap.TEXT_ENTER_175: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.ENTER, size: 1.75};
        case Keycap.TEXT_SHIFT_100: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.SHIFT};
        // case Keycap.TEXT_LOCK_175: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.LOCK, size: 1.75};
        case Keycap.TEXT_UP: return {color:KeycapColourNames.WHITE, imageName: KeycapImageNames.UP};
        case Keycap.TEXT_DOWN: return {color:KeycapColourNames.WHITE, imageName: KeycapImageNames.DOWN};
    
        // ergo, ortho, iso, tsangan split backspace
        case Keycap.PIPE_100: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.PIPE};

        default: 
            throw new Error(`missing KeycapData for ${Keycap[keycap]}`);
    }
}